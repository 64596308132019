export function startScroll(options,id){
    if(!document.getElementById(id)) return
    options = options?options:{}
    // 默认5条数据开始滚动
    const num = options.num||5
    // 设置滚动方向 up down left right
    const dir = options.dir||"up"
    // 设置滚动方式 false || num  默认为false  step滚动方式是间隔式滚动
    options.step = options.step||false
    // 设置滚动时间间隔
    const speed = options.speed||20
    // 设置滚动时间间隔
    let displacement = options.displacement||1
    // 设置滚动加速度  默认匀速  需CSS3 easyin支持
    options.easyin = options.easyin||false
    // 设置是否延时开始滚动 默认为false 可选参数  1\默认5秒后开始滚动，2、num num毫秒后开始滚动，3、moseover，moseleave,click,
    const delay = options.delay??3000
    // console.log(options.delay)
    // console.log(delay)
    // 是否显示翻页配置 boolean
    options.page = options.page||false
    const copy = options.copy
    // 创建滚动容器
    const el = document.getElementById(id)
    let contener = document.createElement("div")
    el.parentNode.replaceChild(contener, el)
    contener.appendChild(el)
    setTimeout(()=>{
      // 检测当前数据条数,根据数据条数决定是否启动scroll，此条必须写在最上部分，节省不必要的内存消耗
      const datanum = el.children.length
      if(datanum < num ){
        return
      }
      // 如果数据条数满足程序运行条件就开始复制html用于无限滚动操作
      if(copy===undefined) el.innerHTML += el.innerHTML
      // 正则提取Y轴坐标
      let transform = el.style.transform
      let transY = transform.replace(/translate\(0px, /g,"").replace(/px\)/g,"")||0
      let range
      let margins
      if(dir==="up"){
        contener.style.overflow = "hidden"
        // 取上下间距
        if(el.children[0].currentStyle){
            margins = parseInt(el.children[0].currentStyle["marginBottom"])+parseInt(el.children[0].currentStyle["marginTop"])
        }else{
            const mb = parseInt(document.defaultView.getComputedStyle(el.children[0],null)["marginBottom"])
            const mt = parseInt(document.defaultView.getComputedStyle(el.children[0],null)["marginTop"])
            margins = mb+mt
        }
        // 存储DOM初始化高度
        const iniHeight = el.offsetHeight+margins*datanum
        contener.style.height = iniHeight+"px"
        el.style.height = datanum *  (el.children[0].offsetHeight+margins)*2 + "px"
        // 实际滚动高度
        range = datanum * (el.children[0].offsetHeight+margins)
        // 重复位移
        let timer = setInterval(()=>{
          if(el===null){
            clearInterval(timer)
            return
          }
          if(transY<-range || transY>0) transY = 0
          transY -= displacement
          el.style.transform = "translate(0px,"+transY+"px)"
        },speed)
      }else{  
        contener.style.overflow = "hidden"
        // 取左右间距
        if(el.children[0].currentStyle){
            margins = parseInt(el.children[0].currentStyle["marginLeft"])+parseInt(el.children[0].currentStyle["marginRight"])
        }else{
            const mb = parseInt(document.defaultView.getComputedStyle(el.children[0],null)["marginLeft"])
            const mt = parseInt(document.defaultView.getComputedStyle(el.children[0],null)["marginRight"])
            margins = mb+mt
        }
        // 存储DOM初始化宽度
        const iniWidth = el.offsetWidth+margins*datanum
        contener.style.width = iniWidth+"px"
        el.style.width = datanum *  (el.children[0].offsetWidth+margins)*2 + "px"
        // 实际滚动宽度
        range = datanum * (el.children[0].offsetWidth+margins)
        // 重复位移
        let timer = setInterval(()=>{
          if(el===null){
            clearInterval(timer)
            return
          }
          if(transY<-range || transY>0) transY = 0
          transY -= displacement
          el.style.transform = "translate("+transY+"px,"+0+"px)"
        },speed)
      }
      // 监听鼠标是否处于mouseover状态
      el.addEventListener("mouseover",function(){
        // 禁止滚动
        displacement = 0
      })
      // 监听鼠标是否移出操作区域
      el.addEventListener("mouseleave",function(){
        displacement = options.displacement||1
      })
      // 监听鼠标滚轮操作
      el.addEventListener("mousewheel",function(e){
        if(e.wheelDelta>0){
          transY += 30
          if(transY>0) transY = -range
        }else{
          transY -= 30
          if(transY<-range) transY = 0
        }
        el.style.transform = "translate(0px,"+transY+"px)"
      })
    },delay)
  }
