<template>
  <section
    v-if="globalLoading"
    class="animated bounceInLeft"
    ref="removeTransform"
  >
    <dl class="classify">
      <dt>仙海区党工委</dt>
      <dd class="border_box" v-for="(e, i) in organization" :key="i">
        <h3 class="title">
          <span class="text"
            >{{ e.o_nature_name }}（{{ e.child.length }}个）</span
          >
        </h3>
        <!-- <div style="height:316px; overflow:hidden;"> -->
        <ul
          class="normal_list org_list space"
          style="
            transform: translate(0px, 0px);
            transition: all 0ms ease-in 0s;
            overflow: hidden;
          "
          :id="i"
          @click="handleClick($event)"
        >
          <li
            v-for="(e, i) in e.child"
            :key="i"
            :eid="e.id"
            :etype="e.o_type_id"
             :class="(i%2 ==0)?'even':'odd'"
          >
            <i :eid="e.id" :etype="e.o_type_id">{{ i + 1 }}</i>
            <span class="party_icon" :eid="e.id" :etype="e.o_type_id"></span>
            <span :eid="e.id" :etype="e.o_type_id">{{ e.o_name }}</span>
          </li>
        </ul>
        <!-- </div> -->
        <!-- <ul class="normal_list org_list space" @click="handleClick($event)">
          <vue-seamless-scroll
            :data="e.child"
            :class-option="optionSingleHeight"
          >
            <li
              v-for="(e, i) in e.child"
              :key="i"
              :eid="e.id"
              :etype="e.o_type_id"
            >
              <i :eid="e.id" :etype="e.o_type_id">{{ i + 1 }}</i>
              <span class="party_icon" :eid="e.id" :etype="e.o_type_id"></span>
              <span :eid="e.id" :etype="e.o_type_id">{{ e.o_name }}</span>
            </li>
          </vue-seamless-scroll>
        </ul> -->
      </dd>
    </dl>
    <div class="baseinfo" id="slower">
      <div class="map">
        <div class="flagaura"></div>
        <img :src="mapurl" />
      </div>
      <ul class="baseinfo_list">
        <li v-for="(e, i) in person" :key="i">
          <ruby>{{ e.name }}</ruby>
          <var>
            <animate-number
              style="color: #e10812; font-weight: bold; font-size: 25px"
              from="1"
              :key="e.num"
              :to="e.num"
              duration="1000"
              easing="easeOutQuad"
            ></animate-number>
          </var>
        </li>
      </ul>
    </div>
    <div class="statistics">
      <div class="statistics_top">
        <div class="border_box">
          <h3 class="title"><span class="text">性别统计</span></h3>
          <div class="charts_area">
            <div class="sexbg">
              <div class="man">
                <p>
                  <animate-number
                    from="0"
                    :key="sex.nan.per"
                    :to="sex.nan.per"
                    duration="1000"
                    easing="easeOutQuad"
                  ></animate-number
                  >%
                </p>
                <p>男</p>
                <p>
                  <animate-number
                    from="0"
                    :key="sex.nan.num"
                    :to="sex.nan.num"
                    duration="1000"
                    easing="easeOutQuad"
                  ></animate-number
                  >人
                </p>
              </div>
              <div class="woman">
                <p>
                  <animate-number
                    from="0"
                    :key="sex.nv.per"
                    :to="sex.nv.per"
                    duration="1000"
                    easing="easeOutQuad"
                  ></animate-number
                  >%
                </p>
                <p>女</p>
                <p>
                  <animate-number
                    from="0"
                    :key="sex.nv.num"
                    :to="sex.nv.num"
                    duration="1000"
                    easing="easeOutQuad"
                  ></animate-number
                  >人
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="border_box">
          <h3 class="title"><span class="text">党龄统计</span></h3>
          <div id="DL" class="charts_area"></div>
        </div>
      </div>
      <div class="statistics_bottom">
        <div class="border_box">
          <h3 class="title"><span class="text">民族统计</span></h3>
          <div id="MZ" class="charts_area"></div>
        </div>
        <div class="border_box">
          <h3 class="title"><span class="text">年龄统计</span></h3>
          <div id="NL" class="charts_area"></div>
        </div>
        <div class="border_box">
          <h3 class="title"><span class="text">学历统计</span></h3>
          <div id="XL" class="charts_area"></div>
        </div>
      </div>
    </div>
  </section>
  <figure v-else>
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</template>
<script>
import * as echarts from "echarts";
import { bubbler } from "@/assets/js/slow";
import { startScroll } from "@/assets/js/scroller";
import {
  PartyChild,
  PartyPerson,
  PartySex,
  PartyAge,
  PartyYears,
  PartyNation,
  PartyEducation,
} from "../api/party";
export default {
  data() {
    return {
      Rechart1: null,
      Rechart2: null,
      Rechart3: null,
      Rechart4: null,
      mapCurrentIndexArr: [0, 1, 2, 3],
      mapCurrentIndex: 0,
      setIntervalArr: [],
      // Y轴坐标
      transY: 0,
      mapurl: "",
      globalLoading: false,
      // 当前接口总数量
      interface: 7,
      globalLoadingState: 0,
      // 组织架构
      organization: {
        nature1: {
          child: [],
        },
        nature2: { child: [] },
        nature3: { child: [] },
        nature4: { child: [] },
      },
      id: 1,
      // 组织基本信息
      person: [],
      // 统计图表
      charts: [],
      // 性别统计
      sex: {
        nv: {
          num: 0,
          per: 0,
        },
        nan: {
          num: 0,
          per: 0,
        },
      },
      DLdata: null,
      XLdata: null,
      NLdata: null,
      MZdata: null,
      Echarts: {
        //党龄
        DL: {
          legend: {
            itemGap: 20,
            top: "10%",
            left: "4%",
            orient: "vertical",
            padding: [30, 10],
            textStyle: {
              color: "#fff",
            },
            // selected: {
            //     // 选中'系列1'
            //     '2年以下': false,
            //     '2-5年': false,
            //     '6-9年': false,
            //     '10-20年': true,
            //     '20年以上': false,
            // }
          },
          series: [
            {
              name: "党龄统计",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["60%", "50%"],
              avoidLabelOverlap: true,
              selectedOffset: 100,
              //文字描述
              label: {
                show: true,
                color: "#ffffff",
                formatter: "{c}",
                normal: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "18",
                    fontWeight: "bold",
                    color: "#fff",
                  },
                  formatter: `{b}\n\n{c}人`,
                },
              },
              //数据线
              labelLine: {
                show: true,
              },
              //移动后文字效果
              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
              data: [
                { value: 1048, name: "2年以下" },
                { value: 735, name: "2-5年" },
                { value: 580, name: "6-9年" },
                { value: 484, name: "10-20年" },
                { value: 300, name: "20年以上" },
              ],
            },
          ],
        },
        //年龄
        NL: {
          // tooltip: {
          //   trigger: "item",
          //   formatter: "{a} <br/>{b} : {c} ({d}%)",
          //   position:["45%","40%"]
          // },
          legend: {
            itemGap: 20,
            top: "2%",
            left: "4%",
            orient: "vertical",
            padding: [30, 10],
            textStyle: {
              color: "#FFFFFF",
            },
          },
          // toolbox: {
          //   show: true,
          //   feature: {
          //     mark: { show: true },
          //     dataView: { show: true, readOnly: false },
          //     restore: { show: true },
          //     saveAsImage: { show: true },
          //   },
          // },
          series: [
            {
              name: "年龄统计",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["60%", "50%"],
              // roseType: 'area',
              //文字描述
              label: {
                show: true,
                fontSize: 18,
                color: "#ffffff",
                normal: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "18",
                    fontWeight: "bold",
                    color: "#fff",
                  },
                  formatter: `{b}\n\n{c}人`,
                },
              },
              //数据线
              labelLine: {
                show: true,
              },
              //移动后文字效果
              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
              data: [],
            },
          ],
        },
        //名族
        MZ: {
          // tooltip: {
          //   trigger: "item",
          //   position:["45%","40%"]
          // },
          legend: {
            itemGap: 20,
            top: "2%",
            left: "2%",
            orient: "vertical",
            padding: [30, 10],
            textStyle: {
              color: "#FFFFFF",
            },
            formatter: "{name}",
          },
          series: [
            {
              name: "民族统计",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["60%", "50%"],
              avoidLabelOverlap: false,
              //文字描述
              label: {
                show: true,
                fontSize: 18,
                color: "#ffffff",
                normal: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "18",
                    fontWeight: "bold",
                    color: "#fff",
                  },
                  formatter: `{b}\n\n{c}人`,
                },
              },
              //数据线
              labelLine: {
                show: false,
              },
              //移动后文字效果
              emphasis: {
                label: {
                  show: true,
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
              data: [],
            },
          ],
        },
        //学历
        XL: {
          // tooltip: {
          //   trigger: "item",
          //   formatter: "{a} <br/>{b} : {c} ({d}%)",
          //   position:["45%","40%"]
          // },
          legend: {
            itemGap: 20,
            top: "2%",
            left: "4%",
            orient: "vertical",
            padding: [30, 10],
            textStyle: {
              color: "#FFFFFF",
            },
          },
          series: [
            {
              name: "学历统计",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["60%", "50%"],
              //文字描述
              label: {
                show: true,
                fontSize: 18,
                color: "#ffffff",
                normal: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: "18",
                    fontWeight: "bold",
                    color: "#fff",
                  },
                  formatter: `{b}\n\n{c}人`,
                },
              },
              //数据线
              labelLine: {
                show: true,
              },
              data: [
                { value: 30, name: "博士及以上" },
                { value: 28, name: "研究生" },
                { value: 26, name: "本科" },
                { value: 214, name: "大专" },
                { value: 232, name: "高中" },
                { value: 22, name: "初中" },
                { value: 122, name: "小学" },
                { value: 222, name: "无" },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    optionSingleHeight() {
      return {
        singleHeight: 63 * 5,
        limitMoveNum: 6,
        waitTime: 5000,
        step: 2,
      };
    },
  },
  watch: {
    globalLoading: function (newval) {
      if (newval) {
        const that = this;
        setTimeout(() => {
          if (!document.getElementById("DL")) return;
          that.ini_charts("DL");
        }, 0);
        setTimeout(() => {
          if (!document.getElementById("MZ")) return;
          that.ini_charts("MZ");
        }, 500);
        setTimeout(() => {
          if (!document.getElementById("NL")) return;
          that.ini_charts("NL");
        }, 1000);
        setTimeout(() => {
          if (!document.getElementById("XL")) return;
          that.ini_charts("XL");
        }, 1500);

        // 监听页面DOM是否加载完成
        if (document.readyState == "complete") {
          setTimeout(() => {
            // 滚动实例ID集
            const scrollIdArr = ["nature1", "nature3", "nature2", "nature4"];
            scrollIdArr.forEach((res) => {
              startScroll({}, res);
            });
          });
          setTimeout(() => {
            bubbler("slower");
            that.$refs.removeTransform.className = "";
          }, 1000);
        }
      }
    },
  },
  methods: {
    getData() {
      this.id = localStorage.getItem("topid");
      let Partydata = { id: this.id, is_reception: 1 };
      let Persondata = { organization_id: this.id };
      // 获取下级组织
      PartyChild(Partydata).then((res) => {
        this.organization = res.data.data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });
      // 获取基本人员
      PartyPerson(Persondata).then((res) => {
        const that = this;
        const speed = 10000;
        const data = res.data.data;
        this.mapurl = data.map;
        for (let i = 0; i < 6; i++) {
          switch (i) {
            case 0: {
              const name = "基层党委总数";
              let obj = {
                name: name,
                num: data.grassroots_party_committee_total,
              };
              setInterval(() => {
                obj.num = 0;
                setTimeout(() => {
                  obj.num = data.grassroots_party_committee_total;
                });
              }, speed);
              that.person.push(obj);
              break;
            }
            case 1: {
              const name = "正式党员人数";
              let obj = {
                name: name,
                num: data.party_member_total,
              };
              setInterval(() => {
                obj.num = 0;
                setTimeout(() => {
                  obj.num = data.party_member_total;
                });
              }, speed);
              this.person.push(obj);
              break;
            }
            case 2: {
              const name = "党总支总数";
              let obj = {
                name: name,
                num: data.general_party_branch_total,
              };
              setInterval(() => {
                obj.num = 0;
                setTimeout(() => {
                  obj.num = data.general_party_branch_total;
                });
              }, speed);
              this.person.push(obj);
              break;
            }
            case 3: {
              const name = "预备党员人数";
              let obj = {
                name: name,
                num: data.pre_party_member_total,
              };
              setInterval(() => {
                obj.num = 0;
                setTimeout(() => {
                  obj.num = data.pre_party_member_total;
                });
              }, speed);
              this.person.push(obj);
              break;
            }
            case 4: {
              const name = "支部总数";
              let obj = {
                name: name,
                num: data.branch_total,
              };
              setInterval(() => {
                obj.num = 0;
                setTimeout(() => {
                  obj.num = data.branch_total;
                });
              }, speed);
              this.person.push(obj);
              break;
            }
            case 5: {
              const name = "积极分子人数";
              let obj = {
                name: name,
                num: data.party_activists_total,
              };
              setInterval(() => {
                obj.num = 0;
                setTimeout(() => {
                  obj.num = data.party_activists_total;
                });
              }, speed);
              this.person.push(obj);
              break;
            }
            default:
              break;
          }
        }
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });

      // 获取性别图表数据
      PartySex(Persondata).then((res) => {
        const that = this;
        const data = res.data.data;
        this.sexcharts = data;
        this.sex.nv.num = res.data.data.female_count;
        this.sex.nan.num = res.data.data.male_count;
        let total = res.data.data.female_count + res.data.data.male_count;
        this.sex.nan.per = parseInt(
          ((this.sex.nan.num / total) * 100).toFixed(2)
        );
        if (this.sex.nv.per < 1) {
          this.sex.nv.per = 1;
        }
        if (this.sex.nan.per < 1) {
          this.sex.nan.per = 1;
        }
        this.sex.nv.per = 100 - this.sex.nan.per;
        setInterval(() => {
          that.sex.nan.num = 0;
          that.sex.nv.num = 0;
          that.sex.nan.per = 0;
          that.sex.nv.per = 0;
          setTimeout(() => {
            that.sex.nan.num = res.data.data.male_count;
            that.sex.nv.num = res.data.data.female_count;
            that.sex.nan.per = parseInt(
              ((that.sex.nan.num / total) * 100).toFixed(2)
            );
            that.sex.nv.per = 100 - this.sex.nan.per;
          });
        }, 9000);
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });
      // 获取党龄图表数据
      PartyYears(Persondata).then((res) => {
        const a = res.data.data;
        let echarts_data = [
          {
            value: a.less_two_year,
            name: "2年以下(" + a.less_two_year_per + ")",
          },
          {
            value: a.between_two_five_year,
            name: "2-5年(" + a.between_two_five_year_per + ")",
          },
          {
            value: a.between_six_nine_year,
            name: "6-9年(" + a.between_six_nine_year_per + ")",
          },
          {
            value: a.between_ten_twenty_year,
            name: "10-20年(" + a.between_ten_twenty_year_per + ")",
          },
          {
            value: a.more_than_twenty_year,
            name: "20年以上(" + a.more_than_twenty_year_per + ")",
          },
        ];
        this.Echarts.DL.series[0].data = echarts_data;
        this.DLdata = echarts_data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });
      // 获取民族图表数据
      PartyNation(Persondata).then((res) => {
        res.data.data.forEach((res) => {
          res.value = res.nation_count;
          res.name = res.nation_name + "(" + res.percent + ")";
          // console.log(res)
          // res.name = res.nation_name
        });
        let echarts_data = res.data.data;
        this.Echarts.MZ.series[0].data = echarts_data;
        this.MZdata = echarts_data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });
      // 获取年龄图表数据
      PartyAge(Persondata).then((res) => {
        // console.log(res.data.data)
        const a = res.data.data;
        let echarts_data = [
          {
            value: a.between_18_25,
            name: "18-25岁(" + a.between_18_25_per + ")",
          },
          {
            value: a.between_26_35,
            name: "26-35岁(" + a.between_26_35_per + ")",
          },
          {
            value: a.between_36_49,
            name: "36-49岁(" + a.between_36_49_per + ")",
          },
          {
            value: a.between_50_60,
            name: "50-60岁(" + a.between_50_60_per + ")",
          },
          { value: a.glt_60, name: "60岁以上(" + a.glt_60_per + ")" },
        ];
        this.Echarts.NL.series[0].data = echarts_data;
        this.NLdata = echarts_data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });
      // 获取学历图表数据
      PartyEducation(Persondata).then((res) => {
        // console.log(res.data.data);
        const a = res.data.data;
        let echarts_data = [
          {
            value: a.doctor_above_count,
            name: "博士及以上(" + a.doctor_above_count_per + ")",
          },
          {
            value: a.graduate_student_count,
            name: "研究生(" + a.graduate_student_count_per + ")",
          },
          {
            value: a.college_student_count,
            name: "本科(" + a.college_student_count_per + ")",
          },
          {
            value: a.junior_middle_school_count,
            name: "大专(" + a.junior_middle_school_count_per + ")",
          },
          {
            value: a.high_school_count,
            name: "高中(" + a.high_school_count_per + ")",
          },
          {
            value: a.junior_college_count,
            name: "初中(" + a.junior_college_count_per + ")",
          },
          {
            value: a.primary_school_count,
            name: "小学(" + a.primary_school_count_per + ")",
          },
          {
            value: a.no_education_count,
            name: "无学历(" + a.no_education_count_per + ")",
          },
        ];
        this.Echarts.XL.series[0].data = echarts_data;
        this.XLdata = echarts_data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
        
      });
    },
    RechartToogle(id) {
      const routers = this.$router.history.current.name;
      if (routers !== "index") return;
      // 切换速度
      // const speed = 5000
      // const that = this
      // let Rechart = echarts.init(document.getElementById(id))
      let Rechart;
      // console.log(Rechart)
      switch (id) {
        case "DL": {
          Rechart = this.Rechart1;
          const currentIndex = 0;
          let isSet = true;
          let index = 0;
          let key = 0;
          this.handleSelectedActive(Rechart, currentIndex, isSet, index, key);
          break;
        }
        case "MZ": {
          Rechart = this.Rechart2;
          const currentIndex = 0;
          let isSet = true;
          let index = 0;
          let key = 1;
          this.handleSelectedActive(Rechart, currentIndex, isSet, index, key);
          break;
        }
        case "NL": {
          Rechart = this.Rechart3;
          const currentIndex = 0;
          let isSet = true;
          let index = 0;
          let key = 2;
          this.handleSelectedActive(Rechart, currentIndex, isSet, index, key);
          break;
        }
        case "XL": {
          Rechart = this.Rechart4;
          const currentIndex = 0;
          let isSet = true;
          let index = 0;
          let key = 3;
          this.handleSelectedActive(Rechart, currentIndex, isSet, index, key);
          break;
        }
        default:
          break;
      }
    },
    handleSelectedActive(myEcharts, currentIndex, isSet, index, key) {
      const that = this;
      const speed = 5000;
      let option = myEcharts.getOption();
      const chartHover = function () {
        const dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        // console.log(that.mapCurrentIndexArr[key]);
        myEcharts.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: that.mapCurrentIndexArr[key],
        });
        that.mapCurrentIndexArr[key] =
          (that.mapCurrentIndexArr[key] + 1) % dataLen;
        // 高亮当前图形
        myEcharts.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: that.mapCurrentIndexArr[key],
        });
        // 显示 tooltip
        myEcharts.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: that.mapCurrentIndexArr[key],
        });
      }
      chartHover()
      // 2、鼠标移动上去的时候的高亮动画
      myEcharts.on("mouseover", function (param) {
        isSet = false;
        clearInterval(that.setIntervalArr[index]);
        // 取消之前高亮的图形
        myEcharts.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: that.mapCurrentIndexArr[key],
        });
        // 高亮当前图形
        myEcharts.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        });
        // 显示 tooltip
        myEcharts.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: param.dataIndex,
        });
        that.mapCurrentIndexArr[key] = param.dataIndex;
      });

      that.setIntervalArr[index] = setInterval(chartHover, speed);
      // 4、鼠标移出之后，恢复自动高亮
      myEcharts.on("mouseout", function () {
        if (!isSet) {
          that.setIntervalArr[index] = setInterval(chartHover, speed);
          isSet = true;
        }
      });
    },
    ini_charts(id) {
      // const that = this
      // const speed = 2000
      // 每次生成一个随机数 5- 15 秒 随机展示int效果
      // let speed = Math.round(Math.random() * 10 + 5)*1000
      // console.log(newspeed)
      switch (id) {
        case "DL": {
          this.Rechart1 = echarts.init(document.getElementById(id));
          this.Rechart1.setOption(this.Echarts.DL);
          this.RechartToogle("DL");
          // console.log(option)
          break;
        }
        case "MZ": {
          this.Rechart2 = echarts.init(document.getElementById(id));
          this.Rechart2.setOption(this.Echarts.MZ);
          this.RechartToogle("MZ");
          break;
        }
        case "NL": {
          this.Rechart3 = echarts.init(document.getElementById(id));
          this.Rechart3.setOption(this.Echarts.NL);
          this.RechartToogle("NL");
          break;
        }
        case "XL": {
          this.Rechart4 = echarts.init(document.getElementById(id));
          this.Rechart4.setOption(this.Echarts.XL);
          this.RechartToogle("XL");
          break;
        }
        default:
          break;
      }
    },
    // 滚动区域不能直接使用CLICK事件，为避免点击无效BUG，使用冒泡事件代理
    handleClick(e) {
      if (
        e.target.attributes.eid === undefined ||
        e.target.attributes.etype === undefined
      ) {
        return;
      }
      const skip = e.target.attributes["etype"].value,
        id = e.target.attributes["eid"].value;
      const that = this;
      skip === "1" &&
        (function () {
          that.$setSession("OrgID", id);
          that.RouteGo("generalLevel", { id: id });
        })();
      skip === "2" &&
        (function () {
          that.$setSession("LowOrgID", id);
          that.RouteGo("lowestLevel", { id: id });
        })();
    },
  },
  created() {
    this.getData();
    // 设置接口超时时间，展示页面
    const outtime = 10000
    setTimeout(()=>{
      this.globalLoading = true
    },outtime)
  },
};
</script>