import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'
import login from '../views/login'
import topLevel from '../views/topLevel'
import topLevelsub from '../views/topLevelsub'
import generalLevel from '../views/generalLevel'
import lowestLevel from '../views/lowestLevel'
import getPageTitle from '../utils/get-page-title'
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  //首页
  {
    path: '/index',
    component: layout,
    children: [{
      name: 'index',
      path: '/index',
      component: topLevel,
      meta: {
        setIndex: "index",
        title: "首页"
      }
    }]
  },
  // 党工委第二屏
  {
    path: '/topLevelsub',
    component: layout,
    children: [{
      name: 'topLevelsub',
      path: '/topLevelsub',
      component: topLevelsub,
    }]
  },
  //小党委
  {
    path: '/generalLevel',
    component: layout,
    children: [{
      name: 'generalLevel',
      path: '/generalLevel',
      component: generalLevel,
    }]
  },
  {
    path: '/lowestLevel',
    component: layout,
    children: [{
      name: 'lowestLevel',
      path: '/lowestLevel',
      component: lowestLevel,
    }]
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

//路由导航守卫
router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  //to 要去的路由配置
  //from 当前的路由配置
  //next 一定要调用，让to的路由配置继续生效，比如如果去登陆直接next(),否则判断token是否存在，如果存在就next()
  const token = localStorage.getItem('token');

  if (token) {
    next()
  } else {
    if (to.path === '/') {
      next()
    } else {
      next({ path: '/' })
    }
  }


  //
  // if (to.path === '/login') return next() ;//使用return，不需要写else
  //
  // //判断其他页面是否有token
  // const token = localStorage.getItem('token');
  //
  // //存在继续往后走
  // if (token) return next();
  //
  //
  // // this.$router.push({name:'login'}) #没有this,无法使用
  // router.push({
  //   name: 'login'
  // })


});

export default router
