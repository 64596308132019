<template>
  <div class="s_main">
    <div class="s_bg animated fadeIn">
      <img src="../assets/images/main_bg.jpg" @load="showAnimate(['s_logo','s_xh_text','s_emblem','s_text'])" />
    </div>
    <div class="s_logo animated fadeIn" id="s_logo" style="display:none;"></div>
    <div class="s_cloud animated" id="s_cloud" style="left:0"></div>
    <div class="s_xh_text animated fadeIn" id="s_xh_text" style="display:none;"></div>
    <div id="s_emblem" class="animated fadeInLeft" style="display:none;">
      <div class="s_emblem"></div>
      <div class="s_no_forgot"></div>
    </div>
    <div class="s_text animated fadeInLeft" id="s_text" style="display:none;">
      <h1>{{info.article_title}}</h1>
      <p>{{info.time}}</p>
      <div>
        {{info.content}}
      </div>
    </div>
    <div class="s_sub_cloud animated fadeIn" id="s_sub_cloud"></div>
    <div class="s_login_handle animated fadeIn" @click="checkLogin">仙海区智慧党建 &gt;</div>
    <div class="s_login" id="s_login" style="display:none;">
      <div class="s_redship"></div>
      <div class="s_login_box">
        <h2>欢迎登录仙海区智慧党建系统</h2>
        <ul class="login_list">
          <li class="icon_01"><input type="text" v-model="login.user_name" placeholder="请输入用户名" autocomplete="off" />
          </li>
          <li class="icon_02"><input type="password" v-model="login.password" placeholder="请输入密码"
              autocomplete="new-password" /></li>
          <li class="icon_03"><input type="text" style="width:296px;" v-model="login.code" @keyup="enterKeyup"
              placeholder="请输入验证码" autocomplete="off" />
            <img :src="code" width="166px" height="51px" @click="codes">
          </li>
        </ul>
        <button class="login_btn" @click="gonext">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
  import "../assets/css/animate.min.css"
  import { Login, Getimgcode, LoginS } from "../api/party";

  export default {
    name: 'Home',
    data() {
      return {
        info: {
          article_title: '',
          column_title: '',
          content: '',
          time: ''
        },
        code: '',
        login: {
          user_name: '',
          password: '',
          code: '',
        },
        leavepage: false
      }
    },
    created() {
      Login().then(res => {
        this.info = res.data.data[1];
      })
      this.codes()
    },
    methods: {
      //获取验证码
      codes() {
        Getimgcode().then(res => {
          this.code = res.data.data.code_src
        })
      },

      ele(id) {
        const el = document.getElementById(id)
        return el
      },
      showAnimate(arr) {
        const speed = 500
        const that = this
        const $$ = this.ele
        // css3控制动画，懒得监听检测transition的值是否为transitionend了，直接使用setTimeout延时模拟回调动画，反正都是定时的，不影响大局
        setTimeout(() => {
          $$(arr[0]).style.display = 'block'
          arr = arr.slice(1)
          arr.length !== 0 ? that.showAnimate(arr) : that.moveCloud()
        }, speed)
      },
      moveCloud() {
        const $$ = this.ele
        const that = this
        let left = parseInt($$('s_cloud').style.left)
        left <= -4110 ? left = 0 : left--
        $$('s_cloud').style.left = left + 'px'
        if (this.leavepage) return
        setTimeout(() => {
          that.moveCloud()
        }, 50)
      },
      showLogin() {
        const $$ = this.ele
        let display = $$('s_login').style.display
        if (display === 'none') {
          $$('s_login').style.display = 'block'
          $$('s_login').className = 's_login animated slideInDown';
        } else {
          $$('s_login').className = 's_login animated slideOutUp'
          setTimeout(() => {
            $$('s_login').style.display = 'none'
          }, 500)
        }
      },// 检测当前是否是登录状态，如果是登录状态，则直接跳转至home页面
      checkLogin() {
        // this.showLogin()
        const that = this
        // 如果曾经登录过且token未过期,就直接跳转到Home页面
        if (localStorage.token !== undefined) {
          this.leavepage = true
          setTimeout(function () {
            that.$router.push({ name: 'index' });
          }, 100)
        } else {
          this.showLogin()
        }
      },
      // 回车登录
      enterKeyup(e) {
        if (e.keyCode === 13) this.gonext()
      },
      gonext() {
        if (this.login.user_name == "") {
          this.mydialog({
            type: "error",
            value: "用户名不能为空"
          })
          return
        }
        if (this.login.password == "") {
          this.mydialog({
            type: "error",
            value: "密码不能为空"
          })
          return
        }
        if (this.login.code == "") {
          this.mydialog({
            type: "error",
            value: "请先输入验证码"
          })
          return
        }
        let data = {
          user_name: this.login.user_name,
          password: this.login.password,
          code: this.login.code,
        }
        LoginS(data).then(res => {
          if (res.data.status == 0) {
            localStorage.setItem('token', res.data.data.token);
            // this.$setSession("topid", res.data.data.organization_id);
            localStorage.setItem('topid', res.data.data.organization_id);
            this.$router.push({ name: 'index' });
          } else {
            this.mydialog({
              type: "error",
              value: res.data.msg
            })
          }
        })
        this.leavepage = true
      }
    },
    mounted() {
      // const w = window.screen.width;
      // const sizing = w/3840
      // document.body.style.transform = 'scale('+sizing+')'
      // document.body.style.transformOrigin = '0 0';
    }
  }
</script>
<style lang="scss" scoped>
  * {
    margin: 0;
    padding: 0;
    font-family: 'Geneva';
    list-style: none;
  }

  input {
    caret-color: #000;
  }

  .s_bg,
  .s_logo,
  .s_cloud,
  .s_xh_text,
  .s_login_handle,
  .s_login,
  .s_emblem,
  .s_no_forgot,
  .s_text,
  .s_sub_cloud {
    position: absolute;
  }

  .icon_01 {
    background: url('../assets/images/index_09.png') no-repeat 25px 18px;
  }

  .icon_02 {
    background: url('../assets/images/index_08.png') no-repeat 25px 18px;
  }

  .icon_03 {
    background: url('../assets/images/index_10.png') no-repeat 25px 18px;
  }

  .s_text {
    left: 102px;
    top: 280px;
    width: 1044px;
    color: #fff;
    text-align: center;

    >h1 {
      font-size: 60px;
      text-align: center;
    }

    >p {
      font-size: 50px;
      padding: 20px 0;
      text-align: center;
    }

    >div {
      text-indent: 2em;
      text-align: left;
      font-size: 31px;
      line-height: 60px;
      height: 540px;
      overflow: hidden;
    }

    >div::-webkit-scrollbar {
      width: 8px;
    }

    >div::-webkit-scrollbar-track {
      background: rgb(200, 200, 200);
      border-radius: 5px;
    }

    >div::-webkit-scrollbar-thumb {
      background: rgb(120, 120, 120);
      border-radius: 5px;
    }
  }

  .s_main {
    width: 100%;
    max-width: 3840px;
    height: 1080px;
    overflow: hidden;
    position: relative;

    .s_logo {
      width: 432px;
      height: 125px;
      background: url('../assets/images/index_04.png');
      left: 102px;
      top: 65px;
    }

    .s_xh_text {
      background: url('../assets/images/index_06.png');
      left: 1163px;
      top: -10px;
      width: 1138px;
      height: 1076px;
    }

    .s_emblem {
      width: 332px;
      height: 342px;
      background: url('../assets/images/index_01.png');
      left: 2754px;
      top: 105px;
    }

    .s_no_forgot {
      width: 1600px;
      height: 408px;
      background: url('../assets/images/index_02.png') no-repeat center;
      left: 2220px;
      top: 400px;
    }

    .s_login_handle {
      width: 514px;
      height: 97px;
      background: url('../assets/images/index_05.png');
      right: 316px;
      bottom: 132px;
      color: #fff;
      text-align: center;
      line-height: 97px;
      font-size: 38px;
      cursor: pointer;
    }

    .s_cloud {
      width: 10187px;
      height: 293px;
      background: url('../assets/images/index_07.png');
      left: 0;
      top: 0;
    }

    .s_sub_cloud {
      width: 900px;
      height: 293px;
      background: url('../assets/images/index_07.png');
      left: 1000px;
      bottom: -4px;
      background-position-x: -4669px;
      transform: rotateX(180deg);
    }

    .s_login {
      width: 1272px;
      padding-left: 665px;
      padding-top: 125px;
      box-sizing: border-box;
      height: 787px;
      border-radius: 10px;
      background: rgba($color: #fff, $alpha: .8);
      position: absolute;
      left: 50%;
      margin-left: -636px;
      top: 50%;
      margin-top: -400px;
      color: #5a5050;
      text-align: left;

      h2 {
        font-size: 40px;
        text-align: left;
        margin-bottom: 35px;
      }

      .s_redship {
        position: absolute;
        background: url('../assets/images/index_03.png');
        width: 557px;
        height: 559px;
        left: 47px;
        top: 107px;
      }

      .login_list {
        li {
          border: 1px #7a7a7a solid;
          height: 52px;
          padding: 5px 0;
          text-align: left;
          margin-right: 54px;
          clear: both;
          zoom: 1;

          input {
            width: 464px;
            border: 0;
            height: 52px;
            line-height: 52px;
            float: left;
            outline: none;
            font-size: 28px;
            background: none;
            padding-left: 78px;
          }
        }

        li+li {
          margin-top: 42px;
        }
      }

      .login_btn {
        background: #0189c6;
        border: 1px #69a7c8 solid;
        height: 70px;
        line-height: 65px;
        letter-spacing: 20px;
        width: 554px;
        margin-top: 35px;
        font-size: 40px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
</style>