export function bubbler(id) {
    let water = document.getElementById(id)
    // 初始化气泡数量
    let i = 0;
    // 控制气泡数量
    const num = 30
    function randomNumber(start, end) {
        return Math.random() * (end - start);
    }
    function generateBubbles() {
        if (i < num) {
            let el = document.createElement('svg'),
            circle = document.createElement('circle'),
            // 控制气泡大小
            size = randomNumber(1, 20);
            // 创建SVG气泡区
            water.appendChild(el);
            // console.log(water.clientWidth)
            // 创建气泡
            el.appendChild(circle);
            el.setAttribute('viewBox', '0 0 100 100');
            el.setAttribute('class', 'bubble');
            el.setAttribute('style', 'width: ' + size + 'px; height: ' + size + 'px; left: ' + randomNumber(1, water.clientWidth) + 'px;');
            circle.setAttribute('cx', 50);
            circle.setAttribute('cy', 50);
            circle.setAttribute('r', 50);
            i++;
        } else {
            clearInterval(cycle);
        }
    }
    generateBubbles();
    let cycle = setInterval(generateBubbles, 250);
}
