<template>
  <section class="main">
      <div class="header">
          <h1>仙海区智慧党建</h1>
          <div class="controler">
              <span @click="gonext" v-if="nextHandle" :class="disabled">下一页</span>
              <span @click="prev" v-if="prevHandle" :class="disabled">返回</span>
              <span @click="warning">退出</span>
          </div>
      </div>
      <div class="mainbody">
          <router-view />
      </div>
  </section>
</template>
<script>
import {Logout} from "@/api/party.js"

export default {
    data(){
        return{
            nextHandle:true,
            prevHandle:false,
            disabled:false
        }
    },
    methods:{
        prev(){
            if(this.disabled) return
            history.go(-1)
        },
        gonext(){
            if(this.disabled) return
            this.RouteGo('topLevelsub')
        },
        warning(){
            // alert('请联系管理员接入红雁管家数据接口')
            Logout().then(res=>{
                console.log(res.data)
                localStorage.clear()
                this.RouteGo("login")
            }).catch(err=>{
                console.log(err)
            })
        },
        switcher(data){
            // console.log(data)
            this.disabled = "disabled"
                switch(data){
                    case "/index":{
                        this.nextHandle = true
                        this.prevHandle = false
                        break
                    }
                    case "index":{
                        this.nextHandle = true
                        this.prevHandle = false
                        break
                    }
                    case "/topLevelsub":{
                        this.nextHandle = false
                        this.prevHandle = true
                        break
                    }
                    default:{
                        this.nextHandle = false
                        this.prevHandle = true
                        break
                    }
                }
            setTimeout(()=>{
                this.disabled = false
            },3000)
        }
    },
    watch:{
        $route(to){
            this.switcher(to.path)
        }
    },
    created(){
        const routerName = this.$router.history.current.name
        this.switcher(routerName)
    }
}
</script>