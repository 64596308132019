<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import 'babel-polyfill'
export default {
  
  data() {
    return {

    }
  },
  methods:{
    // 防高频调用，减少内存消耗
    // func: 回调方法
    // wait: 延时
    // immediate: true=在触发事件时立刻执行一次回调,false=在触发事件时需等待wait时间后才开始执行第一次回调
    noshake(func,wait,immediate){
      let timeout
      return function() {
        let that = this, args = arguments
        let later = function() {
            timeout = null
            if (!immediate) func.apply(that, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(that, args)
      }
    },
    scaleBody(){
      // 监听浏览器缩放百分比，再同比例返回到100%
      const a = 1/devicePixelRatio
      document.documentElement.style.transform = 'scale('+a+')'
      document.documentElement.style.transformOrigin = '0 0'
    }
  },
  
  mounted(){
    // if(window.screen.width<3840){
    //   this.scaleBody()
    //   const that = this
    //   // 防resize抖动
    //   window.addEventListener('resize', that.noshake(function() {
    //     that.scaleBody()
    //   }, 300),true)
    // }
  }
}
</script>
<style lang="scss">
@import 'assets/css/main.scss';
*{
  margin:0;
  padding: 0;
  font-family: 'Geneva';
  list-style: none;
}
html,body{
  width: 3840px;
  height: 1080px;
  // overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

