import request from "./request";


export function Login(){
    return request.get('api/get_covers')
}
export function Getimgcode(){
    return request.get('api/get_code')
}
export function LoginS(data){
    return request.post('api/do_login',data)
}
export function Logout(data){
    return request.post('api/Logout',data)
}

 

/*党工委获取所有下级组织*/
export function PartyChild(data){
    return request.get('api/get_subordinate',data)
}
/*党工委获取所有人员统计*/
export function PartyPerson(data){
    return request.get('api/data_get',data)
}
/*党工委获取性别统计*/
export function PartySex(data){
    return request.get('api/get_sex_count',data)
}
/*党工委获取年龄统计*/
export function PartyAge(data){
    return request.get('api/get_age_count',data)
}
/*党工委获取党龄统计*/
export function PartyYears(data){
    return request.get('api/get_party_years_count',data)
}
/*党工委获取名族统计*/
export function PartyNation(data){
    return request.get('api/get_nation_count',data)
}
/*党工委获取学历统计*/
export function PartyEducation(data){
    return request.get('api/get_education_count',data)
}
/*党工委排行榜*/
export function PartyScore(data){
    return request.get('api/get_scoreboard',data)
}
/*党工委风采展示*/
export function PartyElegant(data){
    return request.get('api/get_elegant_demeanor',data)
}
/*党工委特色亮点*/
export function PartyArticle(data){
    return request.get('api/get_article',data)
}
/*党工委三会一课*/
export function PartyMeetings(data){
    return request.get('api/get_meetings_lesson',data)
}
/*党工委发展党员*/
export function PartyDevelop(data){
    return request.get('api/get_develop_party_member',data)
}
/*党工委收缴党费*/
export function PartyFee(data){
    return request.get('api/get_party_fee_collection',data)
}

/*二级党委基本信息*/
export function SubPartyInfo(data){
    return request.get('api/get_organization',data)
}

//  委员分工之获取指定或全部数据
export function get_committee_member(data){
    return request.get('api/get_committee_member',data)
}
//  三会一课
export function get_meetings_lesson(data){
    return request.get('api/get_meetings_lesson',data)
}
//  组织生活
export function get_organizational_life(data){
    return request.get('api/get_organizational_life',data)
}
//  党组织量化积分排行榜
export function get_scoreboard(data){
    return request.get('api/get_scoreboard',data)
}
//  督查督办
export function get_supervise_handle(data){
    return request.get('api/get_supervise_handle',data)
}
//  党费收缴之获取指定或全部数据
export function get_party_fee_collection(data){
    return request.get('api/get_party_fee_collection',data)
}
//  获取指定id或指定组织或全部组织的党员数据
export function get_party_member(data){
    return request.get('api/get_party_member',data)
}
//  党员量化考核获取
export function get_member_scoreboard(data){
    return request.get('api/get_member_scoreboard',data)
}

//  仙海特色亮点
export function get_special_highlights(data){
    return request.get('api/get_special_highlights',data)
}
 
 























