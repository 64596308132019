
// import PublicApiUrl from "@/network/publicapi.js"
export default {
  install(Vue) {
    // 定义全局变量
    Vue.prototype.GlobalData = {
      OrgIdent: '党组织',
      PositionPrefix: '党委（县、市、区委、工委）'
    }
    // 通用路由跳转
    Vue.prototype.RouteGo = function (name, params) {
      //  检测是否是传入了一个function
      const isFunction = Object.prototype.toString.call(name) === "[object Function]";
      if (isFunction) {
        name()
        return
      }
      // 检测当前是否是一个路由
      const isLink = name === null || name === "" ? false : true
      // 检测当前是否是外链,正则语法剔除HTTPS://或HTTP://开头的，且不区分大小写
      const rules = /^((https|http)?:\/\/)[^\s]+/;
      const isOutSite = rules.test(name)
      // 如果传了NAME过来形成了路由，且不指向外部链接，就调用动态路由匹配
      if (isLink && !isOutSite) {
        this.$router.push({
          name: name,
          params: params
        })
      }
      // 如果是外链，就新窗口打开
      else if (isLink && isOutSite) {
        window.open(name)
      }
      //  如果不是路由就随便撸一发方便测试
      else {
        console.log("您点击了一个非路由非函数的空元素")
      }
    }


    // 公共设置session方法，防止标签页sessionStorage不共享
    Vue.prototype.$setSession = function (name, value) {
      localStorage.setItem(name,value)
      // if (window.opener && Object.getOwnPropertyNames(window.opener).length > 0) {
      //   window.opener.sessionStorage.setItem(name, value)
      // } else {
      //   sessionStorage.setItem(name, value)
      // }
    }
    // 取sessionStorage
    Vue.prototype.$getSession = function (name) {
      // console.log(localStorage.getItem(name))
      return localStorage.getItem(name)
      // if (window.opener && Object.getOwnPropertyNames(window.opener).length > 0) {
      //   return window.opener.sessionStorage.getItem(name)
      // } else {
      //   return sessionStorage.getItem(name)
      // }
    }

    // 取得当前时间
    Vue.prototype.getMyDate = function(){
      let date = new Date()
      date = {
        year : date.getFullYear(),
        month : date.getMonth() + 1,
       // day : date.getDate(),
        hours : date.getHours(),
        minutes : date.getMinutes(),
        seconds : date.getSeconds(),
        day :  date.getDay()
      }
      return date
    }

    // 公共资源网
    Vue.prototype.publicWeb = function(){
      const Web = {
        url:'http://studyadmin.chinahc.wang'
      }
      return Web
    }

    // 时间格式化
    Vue.prototype.parseTime = function (time, cFormat) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string')) {
          if ((/^[0-9]+$/.test(time))) {
            // support "1548221490638"
            time = parseInt(time)
          } else {
            // support safari
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            time = time.replace(new RegExp(/-/gm), '/')
          }
        }

        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        return value.toString().padStart(2, '0')
      })
      return time_str
    }

    // 格式化性别
    Vue.prototype.format = (value) => {
      switch (value) {
        case 1:
          value = "男"
          break
        case 2:
          value = "女"
          break
        case 3:
          value = "是"
          break
        case 4:
          value = "否"
          break
        default:
          value = ""
      }
      return value
    }

    // 提示信息窗口
    Vue.prototype.mydialog = options => {
      // 检测页面是否已有相同提示信息窗口
      const el = document.getElementsByClassName('new_' + options.type).length>0
      if(el) return
      let div = document.createElement("div")
      div.className = 'new_' + options.type + ' fadeInDownBig animated'
      div.innerHTML = options.value
      // 消失时间
      const speed = 2000
      document.body.append(div)
      setTimeout(() => {
        div.className ='new_' + options.type + ' fadeOut animated'
        // document.body.removeChild(div)
        setTimeout(() => {
          document.body.removeChild(div)
        }, 500);
      }, speed);
    }

  }

}
