import axios from 'axios'
//import router from "../router";
// import websocket from "../../src/common/js/websocket";

/**
 * 默认配置
 * isauth 是否认证
 * passerr 错误直接提示,不需要返回
 */
const defaultOpt = {
    passerr: false,
    isauth: true,
}

axios.defaults.withCredentials = true
/**
 * 创建axios实例
 */
export const service = axios.create({
    // baseURL: '',
    // baseURL:'https://statistics.chinahc.wang',
    // baseURL: process.env.NODE_ENV=="development"?"https://screen.xh.hongchaoshijie.com":"https://wisdom.xh.hongchaoshijie.com",
    // baseURL: process.env.VUE_APP_BASE_API, //测试
    // withCredentials: true, // send cookies when cross-domain requests
    // headers:{},
    timeout: 6000 // 超时请求
})

/**
 * 请求拦截器
 * 请求前
 */
service.interceptors.request.use(
    config => {
        if (config.isauth) {
            config.headers['Authorization'] = localStorage.getItem('token')
        }
        config.headers['version'] = 'v1';
        let date = new Date()
        date = parseInt(date.getTime()/1000);
        config.headers['createTime'] = date;
        // const serverToken = servertoken()
        // config.headers['ServerToken'] = 'JZH ' + serverToken.app_token
        // if (config.data) {
        //   config.data.app_time = serverToken.app_time;
        // } else if (config.params) {
        //   config.params.app_time = serverToken.app_time;
        // }
        return config
    },
    error => {
        // do something with request error
        // console.log(error) // for debug
        return Promise.reject(error)
    }
)

/**
 * 响应拦截器
 */
service.interceptors.response.use(
    response => {
        return response
    }
)


/**
 * 请求返回基础封装
 * @param {*} options
 */
function baseRequest(options) {
    const headers = options.headers || {}
    options.headers = headers
    return new Promise((resolve) => {
        service(options).then(response => {
            // store.commit("SETWORKSTATE", true); //控制断网遮罩
            if(response.data.new_token){
                localStorage.setItem('token',response.data.new_token);  
            }
            resolve(response)
        }).catch(error => {
            // console.log(3333)
            // // 服务出现的问题 不需要返回到页面
            // if (error.server) {
            //     console.log(1)
            // } else {
            //     if (options.passerr) {
            //         // 需要抛错到页面
            //         reject(error.data)
            //     } else {
            //         // 不需要
            //         alert(error.msg);
            //     }
            // }
            resolve(error)
        })
    })
}

/**
 * 跳转登录
 */






/**
 * http 请求基础类
 */
const request = ['post', 'put', 'patch'].reduce((request, method) => {
    /**
     *
     * @param {*} url 接口地址
     * @param {*} data object post 参数
     * @param {*} options object axios 配置项
     */
    request[method] = (url, data = {}, options = {}) => {
        return baseRequest(
            Object.assign({
                url,
                data,
                method
            }, defaultOpt, options)
        )
    }
    return request
}, {});

['get', 'delete', 'head'].forEach(method => {
    /**
     *
     * @param {*} url 接口地址
     * @param {*} params object get 参数
     * @param {*} options object axios 配置项
     */
    request[method] = (url, params = {}, options = {}) => {
        return baseRequest(
            Object.assign({
                url,
                params,
                method
            }, defaultOpt, options)
        )
    }
})

export default request
