import Vue from 'vue'
import App from './App.vue'
import router from './router'
import PublicMethod from './assets/js/public'
import 'babel-polyfill'
require('babel-polyfill')
require('es6-promise').polyfill()

Vue.config.productionTip = false
import dataV from '@jiaminghi/data-view'
import axios from 'axios'
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
Vue.prototype.$http= axios
Vue.use(dataV)
Vue.use(PublicMethod)

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')