<template>
  <section v-if="globalLoading" class="animated bounceInLeft" ref="removeTransform">
    <div class="wraper">
      <div class="all_score">
        <div class="border_box">
          <h3 class="title"><span class="text">党组织量化考核积分排行榜</span></h3>
          <p class="ranking_explain">
            登录积分+学习积分+组织生活积分+督查督办积分
          </p>
          <ul class="ranking_list" id="rankingList" @click="handleClick($event)">
            <li v-for="(e, i) in integral.total" :key="i" :eid="e.id" :etype="e.type" :class="(i%2 ==0)?'odd':'even'">
              <aside :eid="e.id">
                <p style="width:100%;" :eid="e.id" :etype="e.type">{{ e.name }}</p>
              </aside>
              <!-- <var>{{ e.sum }}分</var> -->
              <var :eid="e.id" :etype="e.type">-------- 分</var>
            </li>
          </ul>
        </div>
      </div>
      <div class="sort_score">
        <div class="sort_score_list">
          <div class="sort_score_list_box">
            <div class="border_box">
              <h3 class="title"><span class="text">登录积分排行榜</span></h3>
              <ul class="normal_list table_ranking_list thead">
                <li>
                  <i class="sort">排名</i>
                  <span class="org_name">支部名称</span>
                  <span class="score">登录积分</span>
                </li>
              </ul>
              <ul class="normal_list style2 table_ranking_list" id="rankingList1" @click="handleClick($event)">
                <li v-for="(e, i) in integral.login" :key="i" :eid="e.id" :etype="e.type"
                  :class="(i%2 ==0)?'odd':'even'">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="score">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
              </ul>
            </div>
            <div class="border_box">
              <h3 class="title"><span class="text">学习积分排行榜</span></h3>
              <ul class="normal_list table_ranking_list thead">
                <li>
                  <i class="sort">排名</i>
                  <span class="org_name">支部名称</span>
                  <span class="score">学习积分</span>
                </li>
              </ul>
              <ul class="normal_list style2 table_ranking_list" id="rankingList2" @click="handleClick($event)">
                <li v-for="(e, i) in integral.study" :key="i" :eid="e.id" :etype="e.type"
                  :class="(i%2 ==0)?'odd':'even'">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="score" :eid="e.id" :etype="e.type">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="sort_score_list_box">
            <div class="border_box">
              <h3 class="title"><span class="text">组织生活积分排行榜</span></h3>
              <ul class="normal_list table_ranking_list thead">
                <li>
                  <i class="sort">排名</i>
                  <span class="org_name">支部名称</span>
                  <span class="content">会议次数</span>
                  <span class="score">考勤积分</span>
                </li>
              </ul>
              <ul class="normal_list style2 table_ranking_list" id="rankingList3" @click="handleClick($event)">
                <li v-for="(e, i) in integral.life" :key="i" :eid="e.id" :etype="e.type"
                  :class="(i%2 ==0)?'odd':'even'">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="content" :eid="e.id" :etype="e.type">{{e.num}}</span> -->
                  <span class="content" :eid="e.id" :etype="e.type">----</span>
                  <!-- <span class="score">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
              </ul>
            </div>
            <div class="border_box">
              <h3 class="title"><span class="text">督查督办积分排行榜</span></h3>
              <ul class="normal_list table_ranking_list thead">
                <li>
                  <i class="sort">排名</i>
                  <span class="org_name">支部名称</span>
                  <span class="content">任务数量</span>
                  <span class="score">任务积分</span>
                </li>
              </ul>
              <ul class="normal_list style2 table_ranking_list" id="rankingList4" @click="handleClick($event)">
                <li v-for="(e, i) in integral.inspection" :key="i" :eid="e.id" :etype="e.type"
                  :class="(i%2 ==0)?'odd':'even'">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="content" :eid="e.id" :etype="e.type">{{e.task}}</span> -->
                  <span class="content" :eid="e.id" :etype="e.type">----</span>
                  <!-- <span class="score">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="org_show">
      <div class="border_box">
          <h3 class="title"><span class="text">仙海党建风采展示</span></h3>
          <div class="org_show_box animated" ref="org_show_box" id="org_show_box">
            <div class="cover">
              <img :src="SPC.cover" />
            </div>
            <div class="org_show_art">
              <div class="button" @click="getArtInfo">仙海特色亮点</div>
              <div class="content" @click="getArtInfo" v-html="SPC.jianjie"></div>
            </div>
          </div>
      </div>
      <div class="border_box">
          <h3 class="title"><span class="text">发展党员情况</span></h3>
          <div class="dev_charts">
            <div id="DY" style="width: 100%;height: 100%"></div>
          </div>
      </div>
    </div> -->
      <div class="org_show">
        <div class="border_box">
          <h3 class="title"><span class="text">仙海党建风采展示</span></h3>
          <div class="org_show_box animated">
            <div class="cover" @click="getArtInfo(SPC)" ref="cover_show_box">
              <!-- <vue-flux
                  style="height: 100%"
                  :options="Flux.vfOptions"
                  :images="Flux.vfImages"
                  :transitions="Flux.vfTransitions"
                  ref="slider"> -->

              <!-- <template v-slot:preloader>
                  <flux-preloader/>
                </template> -->

              <!-- <template v-slot:caption>
                  <flux-caption/>
                </template> -->

              <!-- <template v-slot:controls>
                  <flux-controls/>
                </template> -->

              <!-- <template v-slot:pagination>
                  <flux-pagination/>
                </template>

                <template v-slot:index>
                  <flux-index/>
                </template> -->
              <!-- </vue-flux> -->
              <img :src="SPC.cover" />
            </div>
            <div class="org_show_art" style="margin-bottom:0!important;margin-top:0!important;height:100%!important;position: relative;">
              <!-- <div class="button" @click="getArtInfo(SPC)">仙海特色亮点</div> -->
              <h5 @click="getArtInfo(SPC)" style="padding-top:5px;">{{SPC.title}}</h5>
              <div class="content" style="height:225px!important;box-sizing:border-box;padding-bottom:0!important;" @click="getArtInfo(SPC)" v-html="SPC.jianjie" ref="org_show_box">
              </div>
              <span class="more morebtn" @click="getArtInfo(SPC)">查看详情</span>
            </div>
          </div>
        </div>
        <div class="bothbox">
          <div class="border_box">
            <h3 class="title"><span class="text">仙海特色亮点</span></h3>
            <!-- <div class="tese_content" v-html="describe_content"></div> -->
            <!-- {{describe_content}} -->
            <video class="tese_content" poster="https://info.chinahc.wang/videocover.png"
              style="width:95%;padding:0;object-fit:fill;margin:auto;height:270px;margin-top:15px" controls
              src="https://info.chinahc.wang/%E8%B5%9E%E8%B5%9E%E6%96%B0%E6%97%B6%E4%BB%A36.23.mp4"></video>
            <span class="more" @click="popupHandle2=true">查看详情</span>
          </div>
          <div class="border_box">
            <h3 class="title"><span class="text">发展党员情况</span></h3>
            <div class="dev_charts">
              <div id="DY" style="width: 100%;height: 100%"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="sub_statistics">
        <div class="border_box">
          <h3 class="title"><span class="text">三会一课统计</span></h3>
          <div class="org_show_box noflex">
            <ul class="topSH_list">
              <li>
                <aside>党支部委员会</aside>
                <var>
                  <animate-number style="color: #fe0000; font-size: 30px; font-weight: bold"
                    v-if="SH.committee_meeting_times>100" from="0" :key="SH.committee_meeting_times"
                    :to="SH.committee_meeting_times" duration="1000" easing="easeOutQuad"></animate-number>
                  <span v-else>--</span><i>次</i>
                </var>
              </li>
              <li>
                <aside>党支部党员大会</aside>
                <var>
                  <animate-number style="color: #fe0000; font-size: 30px; font-weight: bold"
                    v-if="SH.party_meeting_times>100" from="0" :key="SH.party_meeting_times"
                    :to="SH.party_meeting_times" duration="1000" easing="easeOutQuad"></animate-number>
                  <span v-else>--</span><i>次</i>
                </var>
              </li>
            </ul>
            <ul class="topSH_list">
              <li>
                <aside>党小组会</aside>
                <var>
                  <animate-number style="color: #fe0000; font-size: 30px; font-weight: bold"
                    v-if="SH.team_meeting_times>100" from="0" :key="SH.team_meeting_times" :to="SH.team_meeting_times"
                    duration="1000" easing="easeOutQuad"></animate-number>
                  <span v-else>--</span><i>次</i>
                </var>
              </li>
              <li>
                <aside>党课</aside>
                <var>
                  <animate-number style="color: #fe0000; font-size: 30px; font-weight: bold"
                    v-if="SH.party_course_times>100" from="0" :key="SH.party_course_times" :to="SH.party_course_times"
                    duration="1000" easing="easeOutQuad"></animate-number>
                  <span v-else>--</span><i>次</i>
                </var>
              </li>
              <li>
                <aside>其他会议</aside>
                <var>
                  <animate-number style="color: #fe0000; font-size: 30px; font-weight: bold"
                    v-if="SH.other_meeting_times>100" from="0" :key="SH.other_meeting_times"
                    :to="SH.other_meeting_times" duration="1000" easing="easeOutQuad"></animate-number>
                  <span v-else>--</span><i>次</i>
                </var>
              </li>
            </ul>
          </div>
        </div>
        <div class="border_box">
          <h3 class="title"><span class="text">党费收缴</span></h3>
          <div class="dev_charts">
            <div id="DF" style="width: 100%;height: 100%"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup animated fadeIn" v-if="popupHandle">
      <div class="popup_content tese">
        <div class="title">仙海党建风采展示</div>
        <div class="content_fix characteristic">
          <h2>{{artInfo.title}}</h2>
          <p class="time" style="font-size:2.8rem;">发布时间：{{artInfo.finish_time}}</p>
          <div class="content" id="allStepList">
            <div style="font-size:3rem;" v-if="artInfo.content" v-html="artInfo.content"></div>
            <div style="padding:10px 0;">
              <div v-if="currentindex!=0" style="font-size:3rem;line-height:1.5;cursor: pointer;margin-top:10px;"
                @click="godetail(currentindex-1)">
                上一篇: <span style="text-decoration:underline;color:#2440b3">{{FClist[currentindex-1].title}}</span>
              </div>
              <div style="font-size:3rem;line-height:1.5;cursor: pointer;margin-top:10px;"
                @click="godetail(currentindex+1)">
                下一篇: <span style="text-decoration:underline;color:#2440b3">{{FClist[currentindex+1].title}}</span>
              </div>
            </div>
          </div>
        </div>
        <s class="close" @click="popupHandle=false"></s>
      </div>
      <div class="popup_bg"></div>
    </div>
    <div class="popup animated fadeIn" v-if="popupHandle2">
      <div class="popup_content tese">
        <div class="title">仙海特色亮点</div>
        <div class="content_fix characteristic">
          <h2 style="text-align:center;padding-bottom:20px;">{{LDContent.title}}</h2>
          <div class="content" id="allStepList">
            <div style="font-size:3rem;" v-if="LDContent.describe_content" v-html="LDContent.describe_content"></div>
            <div style="padding:10px 0;">
              <div v-if="LD_currentindex!=0" style="font-size:3rem;line-height:1.5;cursor: pointer;margin-top:10px;"
                @click="goLDDetail(LD_currentindex-1)">
                上一篇: <span style="text-decoration:underline;color:#2440b3">{{LDlist[LD_currentindex-1].title}}</span>
              </div>
              <div style="font-size:3rem;line-height:1.5;cursor: pointer;margin-top:10px;"
                @click="goLDDetail(LD_currentindex+1)">
                下一篇: <span style="text-decoration:underline;color:#2440b3">{{LDlist[LD_currentindex+1].title}}</span>
              </div>
            </div>
          </div>
        </div>
        <s class="close" @click="popupHandle2=false"></s>
      </div>
      <div class="popup_bg"></div>
    </div>
  </section>
  <figure v-else>
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</template>
<script>
  import * as echarts from 'echarts';
  import { startScroll } from "@/assets/js/scroller"

  import {
    // PartyChild,
    // PartyPerson,
    // PartySex,
    // PartyAge,
    // PartyYears,
    // PartyNation,
    // PartyEducation,
    PartyScore,
    // PartyElegant,
    PartyArticle,
    PartyMeetings,
    PartyDevelop,
    PartyFee,
    get_special_highlights
  } from "../api/party";
  import {
    // VueFlux,
    // FluxCaption,
    // FluxControls,
    // FluxIndex,
    // FluxPagination,
    // FluxPreloader
  } from 'vue-flux';
  // import $ from 'jquery'
  export default {
    data() {
      return {
        globalLoading: false,
        popupHandle: false,
        popupHandle2: false,
        // 当前接口总数量
        interface: 10,
        describe_content: "",
        globalLoadingState: 0,
        id: 1,
        //总积分排行榜
        integral: {
          total: [],
          study: [],
          login: [],
          inspection: [],
          life: []
        },
        // 仙海党建风采亮点（当前展示）
        SPC: false,
        //党建风采（所有列表）
        FClist: [],
        //当前所在党建风采文章下编号
        currentindex: 0,
        // 弹出党建风采内容
        SPCcontent: "",

        //特色亮点所有列表
        LDlist: [],
        //当前所在特色亮点文章下编号
        LD_currentindex: 0,
        // 弹出党建风采内容
        LDContent: "",
        //轮播
        Flux: {
          vfOptions: {
            autoplay: true,
            delay: 5000
          },
          vfImages: [],
          // vfTransitions: ['Zip','Blocks1','Blinds3d','Explode','Round1','Round2','Kenburn','Waterfall','Concentric', 'cube', 'book', 'wave']
          vfTransitions: ['fade', 'fade', "fade"]
        },
        // 三会一课
        SH: {

        },
        Echarts: {
          //党员发展
          DY: {
            grid: {
              left: "8%",
              right: "5%",
              bottom: "10%",
            },
            xAxis: {
              type: 'category',
              data: ['2017', '2018', '2019', '2020', '2021'],
              axisLine: {
                lineStyle: {
                  color: '#FFFFFF'
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: false,
              axisLine: {
                lineStyle: {
                  color: '#FFFFFF'
                }
              },
            },
            series: [{
              data: [120, 200, 150, 80, 70],
              type: 'bar',
              barWidth: 30,
              hoverOffset: 15,
              itemStyle: {
                normal: {
                  color: "#4c55c8",
                },
              },
              label: {
                color: '#FFFFFF',
                formatter: "{c}" + "人",
                show: true,
                position: "top"
              }
            }]
          },
          //党费收缴
          DF: {
            legend: {
              textStyle: {
                color: '#fff'
              },
              top: "5%",
            },
            grid: {
              left: "8%",
              right: "5%",
              bottom: "10%",
            },
            tooltip: {},
            dataset: {
              dimensions: ['product', '已缴', '未缴'],
              source: [
                { product: '第一季度', '已缴': 2550, '未缴': 50 },
                { product: '第二季度', '已缴': 2550, '未缴': 50 },
                { product: '第三季度', '已缴': 2550, '未缴': 50 },
                { product: '第四季度', '已缴': 2550, '未缴': 0 }
              ]
            },
            xAxis: {
              type: 'category',
              axisLine: {
                lineStyle: {
                  color: '#fff'
                }
              }
            },
            yAxis: {
              splitLine: false,
              axisLine: {
                lineStyle: {
                  color: '#fff'
                }
              }
            },
            series: [
              {
                type: 'bar',
                label: {
                  color: '#fff',
                  formatter: "已缴" + `{@已缴}` + "人",
                  show: true,
                  position: "top"
                },
                itemStyle: {
                  color: '#4c55c8'
                }
              },
              {
                type: 'bar',
                label: {
                  color: '#fff',
                  formatter: "未缴" + "{@未缴}" + "人",
                  show: true,
                  position: "top"
                },
                itemStyle: {
                  color: '#1023e7'
                }
              }
            ]
          },
        }
      }
    },
    watch: {
      globalLoading: function (newval) {
        if (newval) {
          const that = this;
          setTimeout(() => {
            that.ini_charts("DY");
            // 滚动实例ID集
            const scrollIdArr = ["rankingList", "rankingList1", "rankingList2", "rankingList3", "rankingList4"]
            scrollIdArr.forEach(res => {
              startScroll({
                // delay:0,
              }, res)
            })
          }, 0)
          setTimeout(() => {
            that.ini_charts("DF");
          }, 1000)
          setTimeout(() => {
            this.$refs.removeTransform.className = "";
          }, 1000)
        }
      },
      "$route": function (to, from) {
        console.log(to.path, from);
      }
    },
    components: {
      // vueSeamlessScroll,
      // VueFlux,
      // FluxCaption,
      // FluxControls,
      // FluxIndex,
      // FluxPagination,
      // FluxPreloader,
    },
    computed: {
      optionSingleHeight() {
        return {
          singleHeight: 63 * 5,
          limitMoveNum: 6,
          waitTime: 5000,
        }
      },
      optionSingleHeight2() {
        return {
          singleHeight: 58 * 5,
          limitMoveNum: 14,
          waitTime: 5000,
        }
      }
    },
    methods: {
      getData() {
        // const that = this
        this.id = localStorage.getItem('topid')
        //考核积分排行榜
        let ScoreA = { top_organization_id: this.id, ranking_type: 'points_sum', }
        let study = { top_organization_id: this.id, ranking_type: 'learning_points', }
        let login = { top_organization_id: this.id, ranking_type: 'login_points', }
        let inspection = { top_organization_id: this.id, ranking_type: 'inspection_points', }
        let life = { top_organization_id: this.id, ranking_type: 'life_points', }
        let Elegant = { organization_id: this.id }
        //总积分
        PartyScore(ScoreA).then(res => {
          let data = [];
          let total = res.data.data.data[0].points_sum;
          res.data.data.data.forEach(item => {
            data.push({
              name: item.myOrganization.o_name,
              sum: item.points_sum,
              id: item.myOrganization.id,
              type: item.myOrganization.o_type_id,
              width: parseInt((item.points_sum / total * 100)) + '%'
            })
          })
          this.integral.total = data;
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //学习积分
        PartyScore(study).then(res => {
          let data = [];
          res.data.data.data.forEach(item => {
            data.push({
              name: item.myOrganization.o_name,
              id: item.myOrganization.id,
              type: item.myOrganization.o_type_id,
              sum: item.learning_points
            })
          })
          this.integral.study = data;
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //登录积分
        PartyScore(login).then(res => {
          let data = [];
          res.data.data.data.forEach(item => {
            data.push({
              name: item.myOrganization.o_name,
              id: item.myOrganization.id,
              type: item.myOrganization.o_type_id, sum: item.login_points
            })
          })
          this.integral.login = data;
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //督察督办
        PartyScore(inspection).then(res => {
          let data = [];
          res.data.data.data.forEach(item => {
            data.push({
              name: item.myOrganization.o_name,
              sum: item.inspection_points,
              id: item.myOrganization.id,
              type: item.myOrganization.o_type_id,
              task: item.inspection_tasks_num
            })
          })
          this.integral.inspection = data;
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //组织生活
        PartyScore(life).then(res => {
          let data = [];
          res.data.data.data.forEach(item => {
            data.push({
              name: item.myOrganization.o_name,
              id: item.myOrganization.id,
              type: item.myOrganization.o_type_id, sum: item.life_points, num: item.meetings_num
            })
          })
          this.integral.life = data;
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //风采展示
        // PartyElegant(Elegant).then(res => {
        //   res
        //   // this.Flux.vfImages=res.data.data.images_arr
        //   // //轮播随机取样式
        //   // let imgsFun = ['Concentric', 'cube', 'book', 'wave'];
        //   // for (let i = 0; i < 9; i++) {
        //   //   this.Flux.vfTransitions.push(imgsFun[Math.floor((Math.random() * imgsFun.length))])
        //   // }
        //   // console.log(this.Flux)
        //   this.globalLoadingState++;
        //   this.globalLoadingState === this.interface
        //     ? (this.globalLoading = true)
        //     : (this.globalLoading = false);
        //     })
        // 党建风采
        let Elegant2 = { organization_id: this.id, is_show: 1 }
        this.currentindex = 0
        PartyArticle(Elegant2).then(res => {
          this.FClist = res.data.data;
          this.SPC = res.data.data[this.currentindex]
          let arr = []
          res.data.data.forEach(res => {
            arr.push(res.cover)
          })
          //所有文章封面集
          this.Flux.vfImages = arr
          this.randomArt(this.currentindex, res)
          // 遮罩判断
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })

        //特色亮点
        this.LD_currentindex = 0
        get_special_highlights({ organization_id: this.id, is_show: 1 }).then(res => {
          this.LDlist = res.data.data.data;
          if (this.LDlist.length > 0) {
            this.LDContent = this.LDlist[this.LD_currentindex]
          }
        })

        //三会一课
        PartyMeetings(Elegant).then(res => {
          const datas = JSON.parse(JSON.stringify(res.data.data));
          const that = this
          this.SH = res.data.data
          setInterval(() => {
            this.SH.committee_meeting_times = 0
            this.SH.other_meeting_times = 0
            this.SH.party_course_times = 0
            this.SH.party_meeting_times = 0
            this.SH.team_meeting_times = 0
            setTimeout(() => {
              that.SH.committee_meeting_times = datas.committee_meeting_times
              that.SH.other_meeting_times = datas.other_meeting_times
              that.SH.party_course_times = datas.party_course_times
              that.SH.party_meeting_times = datas.party_meeting_times
              that.SH.team_meeting_times = datas.team_meeting_times
            })
          }, 6000)

          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //发展党员
        PartyDevelop(Elegant).then(res => {
          let xAxis = [];
          let data = [];
          res.data.data = res.data.data.reverse()
          res.data.data.forEach(item => {
            xAxis.push(item.year);
            data.push(item.people_num);
          })
          this.Echarts.DY.xAxis.data = xAxis;
          this.Echarts.DY.series[0].data = data;
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
        //收缴党费
        PartyFee(Elegant).then(res => {
          let data = res.data.data.data[0]
          this.Echarts.DF.dataset.source = [
            { product: '第一季度', '已缴': data.first_quarter_paid, '未缴': data.first_quarter_unpaid },
            { product: '第二季度', '已缴': data.second_quarter_paid, '未缴': data.second_quarter_unpaid },
            { product: '第三季度', '已缴': data.third_quarter_paid, '未缴': data.third_quarter_unpaid },
            { product: '第四季度', '已缴': data.fourth_quarter_paid, '未缴': data.fourth_quarter_unpaid }
          ]
          this.globalLoadingState++;
          this.globalLoadingState === this.interface
            ? (this.globalLoading = true)
            : (this.globalLoading = false);
        })
      },
      // 滚动区域不能直接使用CLICK事件，为避免点击无效BUG，使用冒泡事件代理
      handleClick(e) {
        if (e.target.attributes.eid === undefined || e.target.attributes.etype === undefined) {
          return
        }
        const skip = e.target.attributes["etype"].value,
          id = e.target.attributes["eid"].value;
        const that = this
        skip === "1" && function () {
          that.$setSession("OrgID", id);
          that.RouteGo("generalLevel", { id: id });
        }()
        skip === "2" && function () {
          that.$setSession("LowOrgID", id);
          that.RouteGo("lowestLevel", { id: id });
        }()
      },
      // 特色亮点详情
      getArtInfo(item) {
        this.artInfo = item
        this.popupHandle = true
      },
      // 随机动画特色亮点
      randomArt(ei, res) {
        // 检测是否处于弹窗状态
        window.Ttimer = setInterval(() => {
          if (ei < res.data.data.length - 1)
            ei++
          else
            ei = 0
          // 随机动画展示
          // 定义动画种类
          const animatedType = ["fadeInLeft", "fadeInDown", "fadeInUp", "fadeInRight", "bounceIn"]
          const randomType = Math.floor(Math.random() * (0 - 5) + 5) // 0-5随机数向下取整
          if (!this.$refs.org_show_box) {
            clearInterval(window.Ttimer)
            return
          }
          this.$refs.org_show_box.className = "content animated " + animatedType[randomType]
          this.$refs.cover_show_box.className = "cover animated " + animatedType[randomType]
          setTimeout(() => {
            this.$refs.org_show_box.className = "content animated"
            this.$refs.cover_show_box.className = "cover animated"
          }, 1000)
          this.SPC = res.data.data[ei]
        }, 20000)
      },
      // 通过路由阻止JS程序重复执行
      getPath() {
        console.log(this.$route.path)
      },
      ini_charts(id) {
        switch (id) {
          case "DY": {
            this.Rechart1 = echarts.init(document.getElementById(id));
            this.Rechart1.setOption(this.Echarts.DY);
            // setTimeout(()=>{
            //   this.Rechart1.dispose()
            // },speed)
            break;
          }
          case "DF": {
            this.Rechart2 = echarts.init(document.getElementById(id));
            this.Rechart2.setOption(this.Echarts.DF);
            // setTimeout(()=>{
            //   this.Rechart2.dispose()
            // },speed)
            break;
          }
          default:
            break;
        }
        // setTimeout(()=>{
        //   // 检测当前页面是否有需要渲染的DOM对象
        //   if(document.getElementById(id)) this.ini_charts(id)
        // },speed)
      },
      // 上下页切换
      godetail(index) {
        this.currentindex = index;
        this.artInfo = this.FClist[this.currentindex];
        document.getElementById("allStepList").scrollTop = 0;
      },
      //特色亮点上下页切换
      goLDDetail(index) {
        this.LD_currentindex = index;
        this.LDContent = this.LDlist[this.LD_currentindex];
        document.getElementById("allStepList").scrollTop = 0;
      },
    },
    created() {
      // this.globalLoading=true
      this.getData()
      // 设置接口超时时间，展示页面
      const outtime = 3000
      setTimeout(() => {
        this.globalLoading = true
      }, outtime)
    },
  }
</script>
