<template>
  <section v-if="globalLoading" class="animated bounceInLeft" ref="removeTransform">
    <div class="wraper">
      <div class="part1">
        <div class="border_box">
          <h3 class="title">
            <span class="text" v-if="PartyInfo.myNature">{{ PartyInfo.myNature.o_nature_name }}</span>
          </h3>
          <dl class="org_info">
            <dt>
              <img src="../assets/images/dh.png" width="40" height="40" />{{
                PartyInfo.o_name
              }}
            </dt>
            <!-- <dd>
              <dfn>创建时间：</dfn>
              <ins>{{ PartyInfo.found_time }}</ins>
            </dd> -->
            <!-- <dd>
              <dfn>上级组织：</dfn>
              <ins>{{ PartyInfo.myParent.o_name }}</ins>
            </dd> -->
            <dd v-if="PartyInfo.members.sj">
              <dfn style="text-align:right;">书记：</dfn>
              <ins>{{ PartyInfo.members.sj }}</ins>
            </dd>
            <dd v-if="PartyInfo.members.fsj">
              <dfn style="text-align:right;">副书记：</dfn>
              <ins>{{ PartyInfo.members.fsj}}</ins>
            </dd>
            <dd v-if="PartyInfo.members.wy">
              <dfn style="text-align:right;">委员：</dfn>
              <ins>{{ PartyInfo.members.wy }}</ins>
            </dd>
            <dd>
              <dfn>组织简介：</dfn>
              <ins class="eclips" @click="showOrgPopup">{{ PartyInfo.synopsis }}</ins>
            </dd>
          </dl>
        </div>
        <div class="border_box">
          <h3 class="title">
            <span class="text">党组织列表</span>
          </h3>
          <ul class="normal_list org_list2 space" id="scrollList1" @click="handleClick($event)">
            <li
              v-for="(e, i) in childOrg"
              :key="i"
              :eid="e.id"
              :etype="e.o_type_id"
               :class="(i%2 ==0)?'even':'odd'"
            >
              <i :eid="e.id" :etype="e.o_type_id">{{ i + 1 }}</i>
              <span class="party_icon" :eid="e.id" :etype="e.o_type_id"></span>
              <span :eid="e.id" :etype="e.o_type_id">{{ e.o_name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="part2">
        <div class="border_box">
          <h3 class="title"><span class="text">党员结构</span></h3>
          <img src="../assets/images/buwang.jpg" />
          <ul class="party_member_num">
            <li>
              <ruby>党员总数</ruby>
              <var>
                <animate-number
                  style="color: #ffffff; font-size: 18px"
                  from="0"
                  :key="person.one"
                  :to="person.one"
                  duration="5000"
                  easing="easeOutQuad"
                ></animate-number
                >人
              </var>
            </li>
            <li>
              <ruby>预备党员总数</ruby>
              <var>
                <animate-number
                  style="color: #ffffff; font-size: 18px"
                  from="0"
                  :key="person.two"
                  :to="person.two"
                  duration="1000"
                  easing="easeOutQuad"
                ></animate-number
                >人
              </var>
            </li>
            <li>
              <ruby>入党积极分子数</ruby>
              <var>
                <animate-number
                  style="color: #ffffff; font-size: 18px"
                  from="0"
                  :key="person.three"
                  :to="person.three"
                  duration="1000"
                  easing="easeOutQuad"
                ></animate-number
                >人
              </var>
            </li>
          </ul>
        </div>
        <div class="border_box" style="overflow: hidden">
          <h3 class="title"><span class="text">委员分工</span></h3>
          <ul class="divide" id="divide" @click="handleClick2($event)">
              <li
                v-for="(e, i) in person.data"
                :key="i"
                :eid="e.party_member_id"
              >
                <img :src="e.myPartyMember.party_member_avatar||'https://info.chinahc.wang/1624437239669_index_13.png'" width="130" height="182" :eid="e.party_member_id" />
                <p :eid="e.party_member_id">{{ e.cm_name }}</p>
                <p :eid="e.party_member_id">{{ e.cm_post }}</p>
              </li>
          </ul>
          <!-- <ul class="divide" @click="handleClick2($event)">
            <vue-seamless-scroll
              :data="person.data"
              v-if="person.data"
              :class-option="optionLeft"
              class="seamless-warp2"
            >
              <li
                v-for="(e, i) in person.data"
                :key="i"
                :eid="e.party_member_id"
              >
                <img :src="e.cm_portrait" width="130" height="182" :eid="e.party_member_id" />
                <p :eid="e.party_member_id">{{ e.cm_name }}</p>
                <p :eid="e.party_member_id">{{ e.cm_post }}</p>
              </li>
            </vue-seamless-scroll>
          </ul> -->
        </div>
      </div>
      <div class="part4">
        <div class="border_box">
          <h3 class="title">
            <span class="text">党组织量化考核积分排行榜</span>
          </h3>
          <p class="ranking_explain">
            登录积分+学习积分+组织生活积分+督查督办积分
          </p>
          <ul class="ranking_list" @click="handleClick($event)" style="height:298px;" id="scrollList3">
              <li v-for="(e, i) in Ranking" :key="i" :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">
                <aside :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">
                  <p style="width:100%" :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">{{ e.myOrganization.o_name }}</p>
                </aside>
                <!-- <var>{{ e.points_sum }}分</var> -->
                <var :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">---- 分</var>
              </li>
          </ul>
        </div>
        
        <div class="border_box">
          <h3 class="title"><span class="text">三会一课</span></h3>
          <!-- <div class="circleProgress_wrapper">
                  <div class="wrapper right">
                      <div class="circleProgress rightcircle"></div>
                  </div>
                  <div class="wrapper left">
                      <div class="circleProgress leftcircle"></div>
                  </div>
          </div> -->
          <ul class="meeting_times_list">
            <li>
              <var>
                <animate-number
                  style="color: #fe0000; font-size: 30px; font-weight: bold"
                  v-if="SH.committee_meeting_times>0"
                  from="0"
                  :key="SH.committee_meeting_times"
                  :to="SH.committee_meeting_times"
                  duration="1000"
                  easing="easeOutQuad"
                ></animate-number>
                <span v-else>--</span></var>次
            </li>
            <li>
              <var>
                <animate-number
                  style="color: #fe0000; font-size: 30px; font-weight: bold"
                  v-if="SH.party_meeting_times>0"
                  from="0"
                  :key="SH.party_meeting_times"
                  :to="SH.party_meeting_times"
                  duration="1000"
                  easing="easeOutQuad"
                ></animate-number>
                <span v-else>--</span></var>次
            </li>
            <li>
              <var>
                <animate-number
                  style="color: #fe0000; font-size: 30px; font-weight: bold"
                  v-if="SH.team_meeting_times>0"
                  from="0"
                  :key="SH.team_meeting_times"
                  :to="SH.team_meeting_times"
                  duration="1000"
                  easing="easeOutQuad"
                ></animate-number>
                <span v-else>--</span></var>次
            </li>
            <li>
              <var>
                <animate-number
                  style="color: #fe0000; font-size: 30px; font-weight: bold"
                  v-if="SH.party_course_times>0"
                  from="0"
                  :key="SH.party_course_times"
                  :to="SH.party_course_times"
                  duration="1000"
                  easing="easeOutQuad"
                ></animate-number>
                <span v-else>--</span></var>次
            </li>
          </ul>
        </div>
        <!-- <div class="border_box">
          <h3 class="title"><span class="text">学习情况</span></h3>
          <ul class="normal_list study_list thead">
            <li>
              <i class="order_title">排名</i>
              <span class="name_title">支部名称</span>
              <span class="time_title">学习时长（分钟）</span>
            </li>
          </ul>
          <ul class="normal_list style2 study_list" @click="handleClick($event)" id="scrollList1">
              <li v-for="(e, i) in studyTime" :key="i" :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">
                <i class="order" :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">{{ i + 1 }}</i>
                <span class="name" :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">{{ e.myOrganization.o_name }}</span>
                <span class="time" :eid="e.myOrganization.id" :etype="e.myOrganization.o_type_id">----</span>
              </li>
          </ul>
        </div> -->
      </div>
      <div class="part5">
        <!-- <div class="border_box">
          <h3 class="title"><span class="text">督查督办</span></h3>
          <ul class="task_list">
            <li class="task_list_head">
              <span class="taskname_title">任务主题名字</span>
              <span class="orgname_title">组织名称</span>
              <span class="tasknum_title">任务数量</span>
              <span class="time_title">开始时间</span>
              <span class="state_title">完成状态</span>
            </li>
          </ul>
          <ul class="task_list" @click="handleClick($event)" id="scrollList5">
            <li v-for="(e,i) in task" :key="i">
              <span class="taskname"
                >--------------</span
              >
              <span class="orgname" :eid="e.myResponsibleOrganization.id" :etype="e.myResponsibleOrganization.o_type_id">{{e.myResponsibleOrganization.o_name}}</span>
              <span class="tasknum">{{e.tasks_num}}</span>
              <span class="time">{{e.start_time}}</span>
              <span class="state">{{e.myResponsibleOrganization.status}}</span>
            </li>
          </ul>
        </div> -->
        <div class="border_box">
            <h3 class="title"><span class="text">登录积分排行榜</span></h3>
            <ul class="normal_list table_ranking_list thead">
              <li>
                <i class="sort">排名</i>
                <span class="org_name">支部名称</span>
                <span class="score">登录积分</span>
              </li>
            </ul>
            <ul class="normal_list style2 table_ranking_list" style="height:298px;" id="rankingList1" @click="handleClick($event)">
                <li v-for="(e, i) in integral.login" :key="i" :class="(i%2 ==0)?'odd':'even'" :eid="e.id" :etype="e.type">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="score">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
            </ul>
          </div>
          <div class="border_box">
            <h3 class="title"><span class="text">学习积分排行榜</span></h3>
            <ul class="normal_list table_ranking_list thead">
              <li>
                <i class="sort">排名</i>
                <span class="org_name">支部名称</span>
                <span class="score">学习积分</span>
              </li>
            </ul>
            <ul class="normal_list style2 table_ranking_list" style="height:298px;" id="rankingList2" @click="handleClick($event)">
                <li v-for="(e, i) in integral.study" :key="i" :class="(i%2 ==0)?'odd':'even'" :eid="e.id" :etype="e.type">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="score" :eid="e.id" :etype="e.type">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
            </ul>
          </div>
          
        <!-- <div class="border_box">
          <h3 class="title"><span class="text">党费收缴</span></h3>
          <div id="DF" class="df_area"></div>
        </div> -->
      </div>
      <div class="part6">
        <div class="border_box">
            <h3 class="title"><span class="text">组织生活积分排行榜</span></h3>
            <ul class="normal_list table_ranking_list thead">
              <li>
                <i class="sort">排名</i>
                <span class="org_name">支部名称</span>
                <span class="content">会议次数</span>
                <span class="score">考勤积分</span>
              </li>
            </ul>
            <ul class="normal_list style2 table_ranking_list" style="height:298px;" id="rankingList3" @click="handleClick($event)">
                <li v-for="(e, i) in integral.life" :key="i" :class="(i%2 ==0)?'odd':'even'" :eid="e.id" :etype="e.type">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="content" :eid="e.id" :etype="e.type">{{e.num}}</span> -->
                  <span class="content" :eid="e.id" :etype="e.type">----</span>
                  <!-- <span class="score">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
            </ul>
          </div>
        
        <div class="border_box">
            <h3 class="title"><span class="text">督查督办积分排行榜</span></h3>
            <ul class="normal_list table_ranking_list thead">
              <li>
                <i class="sort">排名</i>
                <span class="org_name">支部名称</span>
                <span class="content">任务数量</span>
                <span class="score">任务积分</span>
              </li>
            </ul>
            <ul class="normal_list style2 table_ranking_list" style="height:298px;" id="rankingList4" @click="handleClick($event)">
                <li v-for="(e, i) in integral.inspection" :class="(i%2 ==0)?'odd':'even'" :key="i" :eid="e.id" :etype="e.type">
                  <i class="sort" :eid="e.id" :etype="e.type">{{i+1}}</i>
                  <span class="org_name" :eid="e.id" :etype="e.type">{{ e.name }}</span>
                  <!-- <span class="content" :eid="e.id" :etype="e.type">{{e.task}}</span> -->
                  <span class="content" :eid="e.id" :etype="e.type">----</span>
                  <!-- <span class="score">{{ e.sum }}</span> -->
                  <span class="score" :eid="e.id" :etype="e.type">----</span>
                </li>
            </ul>
          </div>
      </div>
      <div class="part3">
        <div class="border_box">
          <h3 class="title"><span class="text">组织生活</span></h3>
          <ul class="org_meeting_list" id="scrollList2" @click="handleClick3($event)">
              <li
                v-for="(e, i) in OrganizationalLife"
                :key="i"
                 :eid="e.id" 
                 :etype="e.type"
              >
                <img :src="e.life_cover" :eid="e.id" :etype="e.type" width="194" height="110" />
                <div class="content" :eid="e.id" :etype="e.type">
                  <h5 :eid="e.id" :etype="e.type">{{ e.life_name }}</h5>
                  <p :eid="e.id" :etype="e.type">完成时间：{{ e.finished_time }}</p>
                </div>
              </li>
          </ul>
        </div>
        <div class="border_box">
          <h3 class="title"><span class="text">党费收缴</span></h3>
          <div id="DF" class="df_area" style="height:370px; width:100%"></div>
        </div>
      </div>
    </div>
    <!-- 党员弹窗 -->
    <div class="popup member_info_box animated fadeIn" v-if="memberpopupHandle">
      <div class="popup_content">
        <div class="popup_title">党员基本信息</div>
        <div class="content_fix characteristic">
          <dl class="member_popup_info">
            <dt>
              <img
                :src="memberpopupInfo.party_member_avatar"
                alt=""
                width="100"
                height="100"
              />
              <p>{{ memberpopupInfo.party_member_name }}</p>
            </dt>
            <dd>
              <table border="0" cellpadding="0" cellspacing="0" width="100%">
                <tbody>
                  <tr>
                    <th>性别</th>
                    <td>
                      {{ memberpopupInfo.party_member_sex||"暂未录入" }}
                    </td>
                    <th>入党时间</th>
                    <td>{{ memberpopupInfo.time_join_party||"暂未录入" }}</td>
                  </tr>
                  <tr>
                    <th>民族</th>
                    <td>
                      {{ memberpopupInfo.party_member_nation||"暂未录入" }}
                    </td>
                    <th>学历</th>
                    <td>{{ memberpopupInfo.education_time||"暂未录入" }}</td>
                  </tr>
                  <tr>
                    <th>出生年月</th>
                    <td>{{ memberpopupInfo.date_of_birth||"暂未录入" }}</td>
                    <th>工作现状</th>
                    <td>{{ memberpopupInfo.occupation||"暂未录入" }}</td>
                  </tr>
                  <tr>
                    <th>参加工作时间</th>
                    <td>{{ memberpopupInfo.time_to_work }}</td>
                    <th>电话号码</th>
                    <td>{{ memberpopupInfo.party_member_phone||"暂未录入" }}</td>
                  </tr>
                </tbody>
              </table>
            </dd>
          </dl>
        </div>
        <s class="close" @click="memberpopupHandle = false"></s>
      </div>
      <div class="popup_bg" @click="memberpopupHandle = false"></div>
    </div>
    <!-- 组织生活弹窗 -->
    <div v-if="RecordShow" class="popup animated fadeIn">
      <div class="recordshow">
        <div class="close" @click="RecordShow = false"></div>
        <!-- <span @click="toword" class="success_btn" style="position:absolute; left:10px; top:10px;">导出会议纪要</span> -->
        <h1>组织生活(会议、活动)记录</h1>
        <div class="time">会议时间：{{ RecordInfo.life_start_time }} 至 {{ RecordInfo.finished_time }}</div>
        <table class="record_table" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td>会议(活动)名称</td>
              <td colspan="5">{{ RecordInfo.life_name }}</td>
            </tr>
            <tr>
              <td width="16%">主持人</td>
              <td width="16%">{{ RecordInfo.host_people }}</td>
              <td width="16%">记录人</td>
              <td width="16%">{{ RecordInfo.record_people }}</td>
              <td width="16%">地点</td>
              <td>{{ RecordInfo.place }}</td>
            </tr>
            <tr>
              <td>列席人员</td>
              <td>{{ RecordInfo.superior_attendants }}</td>
              <td>应到人数</td>
              <td>{{ RecordInfo.should_arrive_num }}</td>
              <td>实到人数</td>
              <td>{{ RecordInfo.real_to_num }}</td>
            </tr>
            <tr>
              <td colspan="6" class="content">
                <p>主要内容及过程：</p>
                <div class="record_box">
                  <div class="ol" v-html="RecordInfo.primary_content"></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="popup_bg" @click="RecordShow = false"></div>
    </div>
    <!-- 组织简介弹窗 -->
    <div v-if="orginfoHandle" class="popup animated fadeIn">
      <div class="popup_content">
        <div class="popup_title">组织简介</div>
        <div class="org_popup_box">
          {{ PartyInfo.synopsis }}
        </div>
        <s class="close" @click="orginfoHandle = false"></s>
      </div>
      <div class="popup_bg" @click="orginfoHandle = false"></div>
    </div>
  </section>
  <figure v-else>
    <div class="dot white"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </figure>
</template>
<script>
import * as echarts from "echarts";
import {startScroll} from "@/assets/js/scroller"
import {
  SubPartyInfo,
  PartyChild,
  // PartyPerson,
  get_committee_member,
  get_meetings_lesson,
  get_organizational_life,
  get_scoreboard,
  get_supervise_handle,
  PartyFee,
  get_party_member,
  PartyScore
} from "../api/party";
export default {
  data() {
    return {
      //总积分排行榜
      integral: {
        total: [],
        study: [],
        login: [],
        inspection: [],
        life: []
      },
      globalLoading:false,
      // 组织简介弹窗
      orginfoHandle:false,
      // 当前接口总数量
      interface: 12,
      globalLoadingState: 0,
      task:[],
      // 组织ID
      id:false,
      // 党组织基本信息
      PartyInfo: {
        myNature: {
          o_nature_name: "",
          o_name: "",
        },
        myParent: {
          o_name: "",
        },
        o_name: "",
      },
      // 下级党组织列表
      childOrg: false,
      // 人员统计
      person: {
        one: false,
        two: false,
        three: false,
        data: false,
        members:false
      },
      // 党员弹窗信息
      memberpopupInfo: {},
      // 党员弹窗控制器
      memberpopupHandle: false,
      // 三会一课
      SH: false,
      // 组织生活
      OrganizationalLife: false,
      RecordShow: false,
      RecordInfo: {},
      // 党组织量化积分考核
      Ranking: false,
      // 学习时长
      studyTime: false,
      // 党费收缴
      Echarts: {
        DF: {
          legend: {
            textStyle: {
              color: "#fff",
            },
            top:"5%"
          },
          grid:{
              left:"8%",
              right:"5%",
              bottom:"10%",
          },
          tooltip: {
              
          },
          dataset: {
            dimensions: ["product", "已缴", "未缴"],
            source: [
              { product: "第一季度", 已缴: 0, 未缴: 0 },
              { product: "第二季度", 已缴: 0, 未缴: 0 },
              { product: "第三季度", 已缴: 0, 未缴: 0 },
              { product: "第四季度", 已缴: 0, 未缴: 0 },
            ],
          },
          xAxis: {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "#fff",
              },
            },
          },
          yAxis: {
            // splitArea : {show : true},
            splitLine:false,
            axisLine: {
              lineStyle: {
                color: "#fff",
              },
            },
          },
          series: [
            {
              type: "bar",
              label: {
                color: "#fff",
                formatter: "已缴" + `{@已缴}` + "人",
                show: true,
                position: "top",
              },
              itemStyle: { 
                color: '#4c55c8',
                // normal: {
                //   color:function (value){
                //     value
                //     let a = "rgb(" + Math.random()  + ", 1, 1)"
                //     console.log(a)
                //     return '#'+(function(h){
                //         return new Array(7-h.length).join("0")+h
                //       })((Math.random()*0x1000000<<0).toString(16))
                //   }
                // },
              },
            },
            {
              type: "bar",
              label: {
                color: "#fff",
                formatter: "未缴" + "{@未缴}" + "人",
                show: true,
                position: "top",
              },
              itemStyle: {
                color: '#1023e7',
              },
            },
          ],
        },
      },
    };
  },
  props:["params"],
  components: {
    // vueSeamlessScroll,
  },
  watch: {
    globalLoading: function (newval) {
      if (newval) {
        setTimeout(()=>{
          this.initEcharts();
        })
        setTimeout(()=>{
          // 滚动实例ID集
          const scrollIdArr = ["divide"]
          scrollIdArr.forEach(res=>{
            startScroll({
              dir:"left",
              num:5
            },res)
          })
          const scrollIdArr2 = ["scrollList1","scrollList3","scrollList4","scrollList5"]
          scrollIdArr2.forEach(res=>{
            startScroll({
            },res)
          })
          const scrollIdArr3 = ["scrollList2"]
          scrollIdArr3.forEach(res=>{
            startScroll({
              num:2
            },res)
          })
          this.$refs.removeTransform.className = "";
        },1000)
      }
    },
  },
  methods: {
    getData() {
      // const that = this
      this.id = this.$getSession("OrgID")
      // 获取当前组织基本信息
      let Partydata = { id: this.id };
      SubPartyInfo(Partydata).then((res) => {
        this.PartyInfo = res.data.data;
        console.log(777,res.data.data)
        this.person.members=res.data.data.members;
        this.person.one = res.data.data.party_member_total;
        this.person.two = res.data.data.pre_party_member_total;
        this.person.three = res.data.data.party_activists_total;
        // setInterval(()=>{
        //   that.person.one = 0;
        //   that.person.two = 0;
        //   that.person.three = 0;
        //   setTimeout(()=>{
        //     that.person.one = res.data.data.party_member_total;
        //     that.person.two = res.data.data.pre_party_member_total;
        //     that.person.three = res.data.data.party_activists_total
        //   })
        // }, 6000)

        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
      });
      // 获取下级组织
      Partydata = {
        id: this.id,
        is_reception: 1,
        reception_just_children: 1,
      };
      PartyChild(Partydata).then((res) => {
        this.childOrg = res.data.data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
      });
      // 党员结构
      // Partydata = {
      //   organization_id: this.id,
      //   is_reception: 1,
      // };
      // PartyPerson(Partydata).then((res) => {
      //   const data = res.data.data.myOrganization;
      //   if (data === undefined) {
      //     //人员统计
      //     this.person.one = 0;
      //     this.person.two = 0;
      //     this.person.three = 0;
      //   } else {
      //     //人员统计
      //     this.person.one = data.party_member_total;
      //     this.person.two = data.pre_party_member_total;
      //     this.person.three = data.party_activists_total;
      //   }
      // });
      // 委员分工
      Partydata = {
        organization_id: this.id,
        is_reception: 1,
      };
      get_committee_member(Partydata).then((res) => {
        this.person.data = res.data.data.data;
        this.person.data.forEach((os) => {
          switch (os.myPartyMember.party_member_sex) {
            case "1":
              os.myPartyMember.party_member_sex = "男";
              break;
            case "2":
              os.myPartyMember.party_member_sex = "女";
              break;
            default:
              os.myPartyMember.party_member_sex = "未录入";
              break;
          }
        });
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
      });
      // 三会一课
      Partydata = {
        organization_id: this.id,
        is_reception: 1,
      };
      get_meetings_lesson(Partydata).then((res) => {
        // const dataRecorder = JSON.parse(JSON.stringify(res.data.data))
        this.SH = res.data.data;
        // setInterval(()=>{
        //   this.SH.committee_meeting_times = 0;
        //   this.SH.party_course_times = 0;
        //   this.SH.party_meeting_times = 0;
        //   this.SH.team_meeting_times = 0;
        //   setTimeout(()=>{
        //     this.SH.committee_meeting_times = dataRecorder.committee_meeting_times;
        //     this.SH.party_course_times = dataRecorder.party_course_times;
        //     this.SH.party_meeting_times = dataRecorder.party_meeting_times;
        //     this.SH.team_meeting_times = dataRecorder.team_meeting_times;
        //   })
        // }, 6000)

        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
      });
      // 组织生活
      Partydata = {
        organization_id: this.id,
        is_reception: 1,
      };
      get_organizational_life(Partydata).then((res) => {
        this.OrganizationalLife = res.data.data.data;
        // for(let i=0; i<2; i++){
        //   this.OrganizationalLife.push(res.data.data.data[0])
        // }
        this.OrganizationalLife.forEach(res =>{
          res.primary_content = res.primary_content.replace(/\n/g,"<br />")
          res.finished_time = res.finished_time.slice(0,16)
        })
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
      });
      // 党组织量化积分考核+学习情况
      Partydata = {
        top_organization_id: this.id,
        is_reception: 1,
        ranking_type: "points_sum",
      };
      get_scoreboard(Partydata).then((res) => {
        this.studyTime = res.data.data.data
        this.Ranking = res.data.data.data
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
      });

      // const that = this
      //考核积分排行榜
      let study = {top_organization_id: this.id, ranking_type: 'learning_points',}
      let login = {top_organization_id: this.id, ranking_type: 'login_points',}
      let inspection = {top_organization_id: this.id, ranking_type: 'inspection_points',}
      let life = {top_organization_id: this.id, ranking_type: 'life_points',}
      //学习积分
          PartyScore(study).then(res => {
            let data = [];
            res.data.data.data.forEach(item => {
              data.push({
                name: item.myOrganization.o_name, 
            id: item.myOrganization.id,
            type: item.myOrganization.o_type_id,
                sum: item.learning_points
                })
            })
            this.integral.study = data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
          })
          //登录积分
          PartyScore(login).then(res => {
            let data = [];
            res.data.data.data.forEach(item => {
              data.push({name: item.myOrganization.o_name, 
            id: item.myOrganization.id,
            type: item.myOrganization.o_type_id,sum: item.login_points})
            })
            this.integral.login = data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
          })
          //督察督办
          PartyScore(inspection).then(res => {
            let data = [];
            res.data.data.data.forEach(item => {
              data.push({
                name: item.myOrganization.o_name,
                sum: item.inspection_points,
            id: item.myOrganization.id,
            type: item.myOrganization.o_type_id,
                task: item.inspection_tasks_num
              })
            })
            this.integral.inspection = data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
          })
          //组织生活
          PartyScore(life).then(res => {
            let data = [];
            res.data.data.data.forEach(item => {
              data.push({name: item.myOrganization.o_name,
            id: item.myOrganization.id,
            type: item.myOrganization.o_type_id, sum: item.life_points, num: item.meetings_num})
            })
            this.integral.life = data;
        this.globalLoadingState++;
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false);
          })

      // 督查督办
      Partydata = {
        organization_id: this.id,
        is_reception: 1,
        ranking_type: "length_study",
      };
      get_supervise_handle(Partydata).then((res) => {
        this.task = res.data.data.data;
        this.task.forEach((res) => {
          if (res.myResponsibleOrganization === null) {
            res.myResponsibleOrganization = {};
            res.myResponsibleOrganization.o_name = "暂未指定";
          }
          switch (res.myResponsibleOrganization.status) {
            case 1:
              res.myResponsibleOrganization.status = "已完成";
              break;
            case 2: 
              res.myResponsibleOrganization.status = "进行中";
              break;
            case 3:
              res.myResponsibleOrganization.status = "逾期完成";
              break;
            default:
              res.myResponsibleOrganization.status = "无状态"; 
              break;
          }
        })
        this.globalLoadingState++
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false)
      })
      let Elegant = { organization_id: this.id }
      PartyFee(Elegant).then((res) => {
        let data = res.data.data.data[0]
        // console.log(data)
        if (data !== undefined) {
          this.Echarts.DF.dataset.source = [
            {
              product: "第一季度",
              已缴: data.first_quarter_paid,
              未缴: data.first_quarter_unpaid,
            },
            {
              product: "第二季度",
              已缴: data.second_quarter_paid,
              未缴: data.second_quarter_unpaid,
            },
            {
              product: "第三季度",
              已缴: data.third_quarter_paid,
              未缴: data.third_quarter_unpaid,
            },
            {
              product: "第四季度",
              已缴: data.fourth_quarter_paid,
              未缴: data.fourth_quarter_unpaid,
            },
          ];
        }
        this.globalLoadingState++
        this.globalLoadingState >= this.interface
          ? (this.globalLoading = true)
          : (this.globalLoading = false)
      });
    },
    // 组织简介弹窗
    showOrgPopup(){
      this.orginfoHandle = true
    },
    // 党委委员弹窗
    partymemberPopup(e) {
      this.memberpopupHandle = true;
      this.memberpopupInfo = e;
    },
    // 组织生活弹窗
    showOrgInfo(e) {
      this.RecordShow = true;
      this.RecordInfo = e;
    },
    //初始化图表
    initEcharts() {
      if(!document.getElementById("DF")) return
        let DF = echarts.init(document.getElementById("DF"));
        DF.setOption(this.Echarts.DF);
      // const that = this
      // 每次生成一个随机数 5- 15 秒 随机展示int效果
      // let speed = Math.round(Math.random() * 10 + 5)*1000
      // setTimeout(() => {
      //   let DF = echarts.init(document.getElementById("DF"));
      //   DF.setOption(this.Echarts.DF);
      //   setTimeout(()=>{
      //     DF.dispose()
      //     that.initEcharts()
      //   },speed)
      // });
    },
    // 滚动区域不能直接使用CLICK事件，为避免点击无效BUG，使用冒泡事件代理
    handleClick(e) {
      if(e.target.attributes.eid===undefined||e.target.attributes.etype===undefined){
        return
      }
      const skip = e.target.attributes["etype"].value,
      id = e.target.attributes["eid"].value;
      const that = this
      skip === "1" && function(){
        that.$setSession("OrgID", id);
        that.RouteGo("generalLevel", { id: id });
      }()
      skip === "2" && function(){
        that.$setSession("LowOrgID", id);
        that.RouteGo("lowestLevel", { id: id });
      }()
    },
    handleClick2(e) {
      if(e.target.attributes.eid===undefined){
          return
        }
        const id = e.target.attributes["eid"].value
        let Partydata = {id: id, is_reception: 1};
        get_party_member(Partydata).then(res => {
            this.memberpopupInfo = res.data.data[0]
            switch(this.memberpopupInfo.party_member_sex){
                case "1" :
                this.memberpopupInfo.party_member_sex = '男'
                break
                case "2" :
                this.memberpopupInfo.party_member_sex = '女'
                break
                default :
                this.memberpopupInfo.party_member_sex = '未录入'
                break
            }
            this.memberpopupInfo.party_member_avatar = this.memberpopupInfo.party_member_avatar===""?'https://info.chinahc.wang/1624437239669_index_13.png':this.memberpopupInfo.party_member_avatar
            this.memberpopupHandle = true
        })
    },
    handleClick3(e){
      if(e.target.attributes.eid===undefined){
        return
      }
      const id = e.target.attributes.eid.value
      const data = {
        id:id
      }
      get_organizational_life(data).then((res) => {
        res.data.data[0].primary_content = res.data.data[0].primary_content.replace(/\n/g,"<br/>")
        res.data.data[0].primary_content = res.data.data[0].primary_content.replace(/ /g,"&nbsp;")
        this.showOrgInfo(res.data.data[0])
      });
    }
  },
  computed: {
    optionSingleHeight() {
      return {
        singleHeight: 63,
        limitMoveNum: 7,
        waitTime: 5000,
      };
    },
    optionSingleHeight2() {
      return {
        singleHeight: 173,
        limitMoveNum: 4,
        waitTime: 5000,
      };
    },
    optionSingleHeight3() {
      return {
        singleHeight: 63*5,
        limitMoveNum: 6,
        waitTime: 5000,
      };
    },
    optionSingleHeight4() {
      return {
        singleHeight: 58,
        limitMoveNum: 6,
        waitTime: 5000,
      };
    },
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 6,
      };
    },
  },
  mounted() {
    this.getData();
    // 设置接口超时时间，展示页面
    const outtime = 10000
    setTimeout(()=>{
      this.globalLoading = true
    },outtime)
  },
};
</script>